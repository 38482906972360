import React from 'react'
import AuthButtons from "../../learn-page/LearnHeader/AuthButtons";
import PHYSICS_ICON from "../../../assets/img/physics.png";

const PhysicsNavbar = () => {
  return (
    <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          background: "#3b5998",
          width: "100%",
          padding: "10px 34px",
          fontFamily: "'Poppins', sans-serif",
        }}
    >
        <div className="flex items-center gap-3">
            <img
            src={PHYSICS_ICON}
            alt=""
            style={{
                width: "64px",
                height: "64px",
            }}
            />
            <h2 className={`text-white h-auto`}>PHYSICS ENGINE</h2>
        </div>
        <AuthButtons />
    </div>
  )
}

export default PhysicsNavbar