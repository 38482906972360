import React from 'react'
import Select from "react-select";
import { customStyles } from "./customStyles";

const StemSelect = ({ options, defaultValue , onSelectChange, value }) => {
  return (
    <Select
      placeholder={`Select`}
      value={value}
      options={options}
      styles={customStyles}
      defaultValue={defaultValue}
      onChange={(selectedOption) => onSelectChange(selectedOption)}
    />
  )
}

export default StemSelect