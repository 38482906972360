import {FormattedMessage} from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import Box from '../box/box.jsx';
import Dots from './dots.jsx';

import refreshIcon from './icons/refresh.svg';
import bluetoothIcon from './icons/bluetooth-white.svg'
import serialportIcon from './icons/serialport.svg'
// import serialportIcon from './icons/usb-white.svg'

import styles from './connection-modal.css';

const CONNECTIONTYPE = [
    {
        name: 'Bluetooth', 
        img: bluetoothIcon
    },
    {
        name: 'Serial Port', 
        img: serialportIcon
    }
]

const ChooseMethodStep = props => (
    <Box className={styles.body}>
        <Box className={`${styles.activityArea} gap-6`}>
            {CONNECTIONTYPE.map( item => (
                <button key={item.name} className={`flex flex-col gap-3 p-3 items-center justify-center bg-[#3b5998] rounded-lg border border-2`} onClick={item.name === 'Bluetooth' ? props.onBluetoothScanning : props.onScanning}>
                    <img src={item.img} alt="" className='size-8' />
                    <h2 className='text-sm text-white'>{item.name}</h2>
                </button>
            ))}
        </Box>
        <Box className={styles.bottomArea}>
            <Box className={classNames(styles.bottomAreaItem, styles.instructions)}>
                <FormattedMessage
                    defaultMessage="Select your device in the list above."
                    description="Prompt for choosing a device to connect to"
                    id="gui.connection.scanning.instructions"
                />
            </Box>
            <Dots
                className={styles.bottomAreaItem}
                counter={0}
                total={4}
            />
            <button
                className={classNames(styles.bottomAreaItem, styles.connectionButton)}
                onClick={props.onRefresh}
            >
                <FormattedMessage
                    defaultMessage="Refresh"
                    description="Button in prompt for starting a search"
                    id="gui.connection.search"
                />
                <img
                    className={styles.buttonIconRight}
                    src={refreshIcon}
                />
            </button>
        </Box>
    </Box>
);

ChooseMethodStep.propTypes = {
    onScanning: PropTypes.func,
    onBluetoothScanning: PropTypes.func,
    onRefresh: PropTypes.func,
};


export default ChooseMethodStep;
