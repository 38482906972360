import React from 'react';
import { Link } from 'react-router-dom';
import styles from './CustomButton.css';

const CustomButton = ({ children }) => {
	return (
    <Link to='/scratch' className={styles.startLearning}>
      {children}
    </Link>
  );
};

export default CustomButton;
