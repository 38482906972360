import React from "react";
import Select from "react-select";
import { customStyles } from "../../constants/customStyles";
import { monacoThemes } from "../../lib/defineTheme";

const ThemeDropdown = ({ handleThemeChange, theme }) => {
  return (
    <Select
      placeholder={`Select Theme`}
      // options={languageOptions}
      options={monacoThemes}
      value={theme}
      styles={customStyles}
      onChange={handleThemeChange}
    />
  );
};

export default ThemeDropdown;
