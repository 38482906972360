import axios from '../api/axios';
import { showAlertWithTimeout } from './alerts';
// import { setCodeId } from './code-state';
// import { setCodeTitle } from './code-title';

export const CODE_LOADING = 'scratch-gui/codes/CODE_LOADING';
export const CODE_FAILED = 'scratch-gui/codes/CODE_FAILED';

export const CODE_CREATED = 'scratch-gui/codes/CODE_CREATED';
export const CODE_UPDATED = 'scratch-gui/codes/CODE_UPDATED';
export const MY_CODES_LOADED = 'scratch-gui/codes/MY_CODES_LOADED';
export const CODE_LOADED = 'scratch-gui/codes/CODE_LOADED';
export const CODE_UPLOAD_UPDATED = 'scratch-gui/codes/CODE_UPLOAD_UPDATED';
export const CLEAR_CODE_DATA = 'scratch-gui/codes/CLEAR_CODE_DATA';

const codesInitialState = {
	loading: null,
	codeData: {},
	myCodes: [],
	message: '',
	uploadPercentage: 0,
};

const codeReducer = function (state, action) {
	if (typeof state === 'undefined') state = codesInitialState;
	const { type, payload } = action;
	switch (type) {
		case CODE_LOADING:
			return {
				...state,
				loading: true,
			};
		case CODE_CREATED:
		case CODE_UPDATED:
			return {
				...state,
				codeData: payload.code,
				message: payload.message,
				loading: false,
			};
		case CLEAR_CODE_DATA:
			return {
				...state,
				codeData: {},
			};
		case MY_CODES_LOADED:
			return {
				...state,
				myCodes: payload,
				loading: false,
			};
		case CODE_FAILED:
			return {
				...state,
				loading: false,
			};
		case CODE_LOADED:
			return {
				...state,
				codeData: payload,
				loading: false,
			};
		case CODE_UPLOAD_UPDATED:
			return {
				...state,
				uploadPercentage: payload,
			};
		default:
			return state;
	}
};

// const uploadCodeFile = async (file, fileName, isNewFile, dispatch) => {
// 	dispatch({
// 		type: CODE_UPLOAD_UPDATED,
// 		payload: 0,
// 	});
// 	let fileType = file.type;
// 	let response = await axios.get(
// 		`https://app.tinkerbrix.cc/s3/${isNewFile ? 'sign' : 'signOverwrite'}`,
// 		{
// 			params: { objectName: fileName, path: `codes/files/` },
// 		}
// 	);
// 	var signedRequest = response.data.signedUrl;
// 	var options = {
// 		headers: {
// 			'Content-Type': fileType,
// 			'Cache-Control': 'no-cache',
// 		},
// 		onUploadProgress: function (progressEvent) {
// 			var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
// 			dispatch({
// 				type: CODE_UPLOAD_UPDATED,
// 				payload: percentCompleted,
// 			});
// 		},
// 	};
// 	await axios.put(signedRequest, file, options);
// 	return response.data.fileKey;
// };

const createCode =
	({ codeName, code, codeType }, closeModal) =>
	async dispatch => {
		try {
			dispatch({
				type: CODE_LOADING,
			});
			const res = await axios.post(
				'/api/code/createCode',
				{ codeType, codeName, code },
				{
					headers: {
						'Content-Type': 'application/json',
					},
				}
			);
            console.log(res.data);
            
			// closeModal();
			showAlertWithTimeout(dispatch, 'createSuccess');
			dispatch({
				type: CODE_CREATED,
				payload: res.data,
			});
		} catch (err) {
			console.log(err);
			if (!err.response.data.errors) {
				//todo add alerts and all
			} else {
			}
			dispatch({
				type: CODE_FAILED,
			});
		}
	};

const updateCode =
	({ codeName, code, codeType, codeId }, editCompleted) =>
	async dispatch => {
		try {
			showAlertWithTimeout(dispatch, 'saving');
			dispatch({
				type: CODE_LOADING,
			});
			const res = await axios.post(
				`/api/code/updateCode/${codeId}`,
				{ code, codeName, codeType },
				{
					headers: {
						'Content-Type': 'application/json',
					},
				}
			);
			// hideModal();
			editCompleted();
			getMyCodes();
			showAlertWithTimeout(dispatch, 'saveSuccess');
			dispatch({
				type: CODE_UPDATED,
				payload: res.data,
			});
		} catch (err) {
			console.log(err);
			if (!err.response.data.errors) {
				//todo add alerts and all
			} else {
			}
			dispatch({
				type: CODE_FAILED,
			});
		}
	};

const getMyCodes = () => async dispatch => {
	try {
		dispatch({
			type: CODE_LOADING,
		});
		const res = await axios.get('/api/code/myCodes');
		dispatch({
			type: MY_CODES_LOADED,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
		if (!err.response.data.errors) {
			//todo add alerts and all
		} else {
		}
		dispatch({
			type: CODE_FAILED,
		});
	}
};

//****************Check****************//
const loadCode = (codeData, hideModal) => async dispatch => {
	dispatch({
		type: CODE_LOADED,
		payload: codeData,
	});
	hideModal();
};

// const saveCode =
// 	({ codeFile, fileName }) =>
// 	async dispatch => {
// 		showAlertWithTimeout(dispatch, 'saving');
// 		try {
// 			await uploadCodeFile(codeFile, fileName, false, dispatch);
// 			showAlertWithTimeout(dispatch, 'saveSuccess');
// 		} catch (err) {
// 			showAlertWithTimeout(dispatch, 'savingError');
// 		}
// 	};

// const clearCurrentCode = () => {
// 	return {
// 		type: CLEAR_CODE_DATA,
// 	};
// };

export {
	codeReducer as default,
	codesInitialState,
	createCode,
	getMyCodes,
	loadCode,
	// saveCode,
	updateCode,
	// clearCurrentCode,
};
