import React from 'react';
import IframePage from './iframe-page.jsx';

export default function ExtendedReality() {
	return (
		<IframePage
			iframe={
				<iframe
					src='https://ar.simplem.in'
					width='100%'
					height='100%'
					frameborder='0'
					marginwidth='0'
					marginheight='0'
					allowFullScreen
					style={{
						position: 'absolute',
						top: '60px',
						left: 0,
						right: 0,
						bottom: 0,
						border: 'none',
					}}
				/>
			}
		/>
	);
}
