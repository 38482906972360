import React from 'react'
import { Button } from "@material-ui/core";
import PHYSICS_ICON from "../../../assets/img/physics.png"
import PHYSICS_EXAMPLES_ICON from "../../../assets/img/physics_examples.png"
import GAMES_ICON from "../../../assets/img/gamepad.png"

const PhysicsLandingMenu = ({setMenuOpened}) => {
  return (
    <div className="flex items-strech justify-center flex-wrap gap-8 mt-[10vh]">
        <div className="bg-white rounded-xl overflow-hidden p-4 flex flex-col md:w-[325px]">
            <div className="w-full flex justify-center items-center bg-[#f5f5f5] rounded-xl px-8 py-6 mb-4 relative">
                <div className='flex justify-center items-center absolute top-0 left-0 right-0 bottom-0 bg-[#FFFFFF80] rounded-[8px]'>
                    <h3 className='uppercase text-center' style={{ fontFamily: "Poppins, 'sans serif'", fontWeight: '900', fontSize: '1.75rem', color: '#000000' }}>Coming Soon...</h3>
                </div>
                <img src={PHYSICS_ICON} alt="" className="h-[140px]" />
            </div>
            <h2 className="text-2xl opacity-40">Physics Engine</h2>
            <p className="text-[#00000080] text-sm mt-1 mb-4 opacity-40">Interactive physics simulations to demonstrate basic physics concepts in the classroom.</p>
            <Button variant="contained" disabled style={{ background:"#3b599840", fontWeight:"600", color: "#FFFFFF40", width: "100%", fontFamily: "Poppins" }} >Open Physics Engine</Button>
        </div>
        <div className="bg-white rounded-xl overflow-hidden p-4 flex flex-col md:w-[325px]">
            <div className="w-full flex justify-center items-center bg-[#f5f5f5] rounded-xl px-8 py-6 mb-4">
                <img src={PHYSICS_EXAMPLES_ICON} alt="" className="h-[140px]" />
            </div>
            <h2 className="text-2xl">Examples</h2>
            <p className="text-[#00000080] text-sm mt-1 mb-4">Prebuild interactive physics simulations to demonstrate what are the possible outcome.</p>
            <Button onClick={() => setMenuOpened('examples')} variant="contained" style={{ background:"#3b5998", fontWeight:"600", color: "#FFFFFF", width: "100%", fontFamily: "Poppins" }} >Checkout Examples</Button>
        </div>
        <div className="bg-white rounded-xl overflow-hidden p-4 flex flex-col md:w-[325px]">
            <div className="w-full flex justify-center items-center bg-[#f5f5f5] rounded-xl px-8 py-6 mb-4">
                <img src={GAMES_ICON} alt="" className="h-[140px]" />
            </div>
            <h2 className="text-2xl">Simple Games</h2>
            <p className="text-[#00000080] text-sm mt-1 mb-4">Interactive games build with interactive physics simulations.</p>
            <Button onClick={() => setMenuOpened('games')} variant="contained" style={{ background:"#3b5998", fontWeight:"600", color: "#FFFFFF", width: "100%", fontFamily: "Poppins", marginTop: "auto" }} >Open Games</Button>
        </div>
    </div>
  )
}

export default PhysicsLandingMenu