import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import LOGO from '../../../assets/logo.png'
import styles from '../Login/Login.css'
import { updatePassword } from '../../../reducers/auth';
import { useDispatch } from 'react-redux';

const Forgot = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const _id = searchParams.get("id")
	const [password, setPassword] = useState('');
	const [password2, setPassword2] = useState('');
	const [error, setError] = useState('');
	const [isShowPasswordChecked, setIsShowPasswordChecked] = useState(false);
  const handleUpdatePassword = (e) => {
    e.preventDefault();
    console.log({ verificationToken:_id, password });
    if(password !== password2){
      setError({message: "Password did not match!"})
    } else if(password.length < 7){
      setError({message: "Password is too short!"})
    } else if(password.length > 12){
      setError({message: "Password is too long!"})
    } else {
      dispatch(updatePassword({ verificationToken:_id, password }))
      navigate('/')
    }
  }
  useEffect(() => {
    if(!_id){ 
      navigate('/')
    }
  
  }, [_id])
  
  return (
    <div>
      <div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					background: '#3b5998',
          width: '100%',
					padding: '10px 34px',
          fontFamily: "'Poppins', sans-serif"
				}}
			>
        <div className="flex items-center gap-2">
          <img 
            src={LOGO} 
            alt="" 
            className='w-[220px]'
          />
        </div>
			</div>
      <form 
        onSubmit={handleUpdatePassword} 
        className={`${styles.loginForm} mt-[45px] border rounded-xl w-full max-w-[500px] mx-auto px-8 py-10 flex flex-col items-center`} 
        style={{fontFamily: "'Poppins', sans-serif"}}
      >
        <h2 className='text-center text-[#3b5998]'>Update Password</h2>
        <div className={`${styles.formInput} mt-[13px] w-full`}>
          <p className='mb-1 text-sm font-medium'>
            <label htmlFor='password'>Password</label>
          </p>
          <label className={`input input-bordered flex items-center gap-2 ${error ? "border-[#ff0033]" : ""}`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="currentColor"
              className="h-4 w-4 opacity-70">
              <path
                fillRule="evenodd"
                d="M14 6a4 4 0 0 1-4.899 3.899l-1.955 1.955a.5.5 0 0 1-.353.146H5v1.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2.293a.5.5 0 0 1 .146-.353l3.955-3.955A4 4 0 1 1 14 6Zm-4-2a.75.75 0 0 0 0 1.5.5.5 0 0 1 .5.5.75.75 0 0 0 1.5 0 2 2 0 0 0-2-2Z"
                clipRule="evenodd" />
            </svg>
            <input 
              type={isShowPasswordChecked ? 'text' : 'password'}
              placeholder='Enter your password'
              className={`grow outline-none`}
              style={{ color: 'black' }}
              onChange={e => setPassword(e.target.value)}
            />
          </label>
        </div>
        <div className={`${styles.formInput} mt-[13px] w-full`}>
          <p className='mb-1 text-sm font-medium'>
            <label htmlFor='password'>Confirm Password</label>
          </p>
          <label className={`input input-bordered flex items-center gap-2 ${error ? "border-[#ff0033]" : ""}`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="currentColor"
              className="h-4 w-4 opacity-70">
              <path
                fillRule="evenodd"
                d="M14 6a4 4 0 0 1-4.899 3.899l-1.955 1.955a.5.5 0 0 1-.353.146H5v1.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2.293a.5.5 0 0 1 .146-.353l3.955-3.955A4 4 0 1 1 14 6Zm-4-2a.75.75 0 0 0 0 1.5.5.5 0 0 1 .5.5.75.75 0 0 0 1.5 0 2 2 0 0 0-2-2Z"
                clipRule="evenodd" />
            </svg>
            <input 
              type={isShowPasswordChecked ? 'text' : 'password'}
              placeholder='Enter your password'
              className={`grow outline-none`}
              style={{ color: 'black' }}
              onChange={e => setPassword2(e.target.value)}
            />
          </label>
        </div>
        {error ? <p className='text-[#ff0033] text-xs mt-1 w-full'>{error?.message}</p> : ''}
        <div className="form-control flex flex-row justify-between items-center my-2 w-full">
          <label className="label cursor-pointer justify-start gap-2">
            <input type="checkbox" checked={isShowPasswordChecked} className="checkbox checkbox-sm" onChange={() => setIsShowPasswordChecked(!isShowPasswordChecked)} />
            <span className="label-text font-normal">Show Password</span>
          </label>
        </div>
        <button className='btn btn-neutral bg-[#3b5998]' type='submit'>
          Update Password
        </button>
      </form>
    </div>
  )
}

export default Forgot