import React, { useState, useEffect, useRef } from 'react';
import ROBO_LOGIN from '../../../assets/robo_login.png';
import GOOGLE_ICON from '../../../assets/google.svg';
import styles from './Login.css';
import { useDispatch, useSelector } from 'react-redux';
import { forgot, googleLogin, login } from '../../../reducers/auth';
import { useGoogleLogin } from '@react-oauth/google';
import { responseGoogle } from '../../../util/general';

const Login = () => {
	const [email, setEmail] = useState('');
	const modalRef = useRef();
	const [password, setPassword] = useState('');
	const [emailErr, setEmailErr] = useState('');
	const [passErr, setPassErr] = useState('');
	// const [showing, setShowing] = useState(false);
	const [isShowPasswordChecked, setIsShowPasswordChecked] = useState(false);
	const dispatch = useDispatch();
	const isAuthenticated = useSelector(state => state.scratchGui.auth.isAuthenticated);
	const error = useSelector(state => state.scratchGui.auth.error);
	const loading = useSelector(state => state.scratchGui.auth.loading);

	useEffect(() => {
		if (isAuthenticated) {
			modalRef.current.checked = false;
		}
	}, [isAuthenticated]);

	const gLogin = useGoogleLogin({
		onSuccess: (code) => dispatch(googleLogin({ code })),
		onError: () => window.alert("Somthing went wrong!"),
		flow: 'auth-code',
	});

	const handleLoginUser = e => {
		e.preventDefault();
		dispatch(login({ email, password }));
	};

	const handleForget = () => {
		dispatch(forgot(email));
	};

	return (
		<>
			<input ref={modalRef} type='checkbox' id='login-modal' className='modal-toggle' />
			<div className='modal modal-top sm:modal-middle'>
				<div className={`modal-box relative w-11/12 !max-w-[50rem] bg-white py-12 ${styles.loginModal}`}>
					<label htmlFor='login-modal' className={`absolute right-10 top-8 ${styles.closeBtn}`}>
						✕
					</label>
					<h3 className={`my-2 mx-auto text-xl font-normal text-black text-center mb-3 ${styles.loginTitle}`}>
						Hey there! Login to Robospecies!
					</h3>
					<div className='flex items-end'>
						<div className={`w-full md:w-1/2 px-5 ${styles.modalBody}`}>
							{/* <div className={`flex justify-around ${styles.loginUser}`}>
								<p className={`text-center py-3 user-item ${styles.activeUser}`}>Student</p>
								<p className={`text-center py-3 ${styles.userItem}`}>Parent</p>
								<p className='text-center py-3'>Teacher</p>
							</div> */}
							<form onSubmit={handleLoginUser} className={`${styles.loginForm} mt-[25px]`}>
								<div className={styles.formInput}>
									<p className='mb-1'>
										<label htmlFor='email'>Email</label>
									</p>
									<input
										type='email'
										placeholder='Enter Your Email'
										className={`${error ? 'border border-[#ff0033]' : ''}`}
										style={{ color: 'black' }}
										onChange={e => setEmail(e.target.value)}
									/>
								</div>
								{error ? <p className='text-[#ff0033] text-xs mt-1'>{error?.message}</p> : ''}
								<div className={`${styles.formInput} mt-[13px]`}>
									<p className='mb-1'>
										<label htmlFor='password'>Password</label>
									</p>
									<input
										type={isShowPasswordChecked ? 'text' : 'password'}
										placeholder='Enter your password'
										className={`${error ? 'border border-[#ff0033]' : ''}`}
										style={{ color: 'black' }}
										onChange={e => setPassword(e.target.value)}
									/>
								</div>
								{error ? <p className='text-[#ff0033] text-xs mt-1'>{error?.message}</p> : ''}
								<div className="form-control flex flex-row justify-between items-center my-2">
									<label className="label cursor-pointer justify-start gap-2">
										<input type="checkbox" checked={isShowPasswordChecked} className="checkbox checkbox-sm" onChange={() => setIsShowPasswordChecked(!isShowPasswordChecked)} />
										<span className="label-text font-normal">Show Password</span>
									</label>
									<p className='text-sm text-[#E86969] cursor-pointer font-normal' onClick={() => handleForget()}>Forgot Password?</p>
								</div>
								<p className='text-center'>
									<button disabled={loading} type='submit'>
										{loading ? 'Logging In...' : 'Log In'}
									</button>
								</p>
							</form>
							<div className='divider text-black text-base font-normal my-6'>Or With</div>
							{/* Social Login */}
							<button className={`flex justify-center items-center w-full ${styles.socialLogin}`} onClick={() => gLogin()}>
								<p>
									<img className='w-[24px]' src={GOOGLE_ICON} alt='' />
								</p>
								<p className={styles.googleLoginText}>Login with Google</p>
							</button>
							<p className={styles.signupText}>
								Don’t have an account?
								<label htmlFor='signup-modal' className='ml-2' onClick={() => (modalRef.current.checked = false)}>
									Sign up
								</label>
							</p>
						</div>
						<div className={`w-1/2 hidden md:flex items-end justify-center`}>
							<img src={ROBO_LOGIN} alt="" className='w-9/12 object-contain' />
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Login;
