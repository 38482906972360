exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E5F0FF */ /* #E9F1FC */ /* #D9E3F2 */ /* 90% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* #4C97FF */ /* #3373CC */ /* 35% transparent version of motion-primary */ /* 15% transparent version of motion-primary */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ .turbo-mode_turbo-container_1qQFO {\r\n    display: -webkit-box;\r\n    display: -webkit-flex;\r\n    display: -ms-flexbox;\r\n    display: flex;\r\n    -webkit-box-align: center;\r\n    -webkit-align-items: center;\r\n        -ms-flex-align: center;\r\n            align-items: center;\r\n    padding: 0.25rem;\r\n    -webkit-user-select: none;\r\n       -moz-user-select: none;\r\n        -ms-user-select: none;\r\n            user-select: none;\r\n} .turbo-mode_turbo-icon_A_vJA {\r\n    margin: 0.25rem;\r\n} .turbo-mode_turbo-label_1v23d {\r\n    font-size: 0.625rem;\r\n    font-weight: bold;\r\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\r\n    color: hsla(38, 100%, 55%, 1);\r\n    white-space: nowrap;\r\n}\r\n", ""]);

// exports
exports.locals = {
	"turbo-container": "turbo-mode_turbo-container_1qQFO",
	"turboContainer": "turbo-mode_turbo-container_1qQFO",
	"turbo-icon": "turbo-mode_turbo-icon_A_vJA",
	"turboIcon": "turbo-mode_turbo-icon_A_vJA",
	"turbo-label": "turbo-mode_turbo-label_1v23d",
	"turboLabel": "turbo-mode_turbo-label_1v23d"
};