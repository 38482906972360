var escape = require("../../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Parents_parents_1QgAa{background:url(" + escape(require("../../../assets/bg-parent.png")) + ");background-repeat:no-repeat;background-size:cover;background-position:top}.Parents_parents_1QgAa .Parents_parent-title_3w9li{font-size:24px;font-weight:700;line-height:31px;color:#0c2032;margin-bottom:48px}.Parents_parents-text_15oOq{font-weight:400;font-size:18px;line-height:26px;color:#414141}", ""]);

// exports
exports.locals = {
	"parents": "Parents_parents_1QgAa",
	"parent-title": "Parents_parent-title_3w9li",
	"parentTitle": "Parents_parent-title_3w9li",
	"parents-text": "Parents_parents-text_15oOq",
	"parentsText": "Parents_parents-text_15oOq"
};