import React from 'react';
import facebook from '../../assets/facebook-logo.svg';
import instagram from '../../assets/instagram-logo.svg';
import linkedin from '../../assets/linkedin-logo.svg';
import logo from '../../assets/logo.svg';

const Footer = () => {
	return (
		<footer className='md:footer px:5 md:px-10 py-20 bg-[#284681] text-white md:flex justify-around'>
			<div className='block md:hidden'>
				<div className='flex justify-center flex-col items-center'>
					<img className='w-[200px]' src={logo} alt='' />
					<p className='mt-16 mb-3'>
						<span>Educators</span>
					</p>
					<p className='mb-3'>
						<span>Parents</span>
					</p>
					<p className='mb-3'>
						<span>Resources</span>
					</p>
					<div className='flex gap-5 mt-5'>
						<a href='#link'>
							<img className='w-[56px]' src={facebook} alt='' />
						</a>
						<a href='#link'>
							<img className='w-[43px] mt-[8px]' src={linkedin} alt='' />
						</a>
						<a href='#link'>
							<img className='w-[40px] mt-[11px]' src={instagram} alt='' />
						</a>
					</div>
				</div>
			</div>
			<div className='hidden md:block'>
				<div className='text-[#2F2141] px-3 py-3'>
					<img className='w-[200px]' src={logo} alt='' />
				</div>
				<div className='flex gap-5 mt-8'>
					<a href='#link'>
						<img className='size-[42px]' src={facebook} alt='' />
					</a>
					<a href='#link'>
						<img className='size-[33px] mt-[7px]' src={linkedin} alt='' />
					</a>
					<a href='#link'>
						<img className='w-[31px] mt-[6px]' src={instagram} alt='' />
					</a>
				</div>
			</div>
			<div className='hidden md:block'>
				<span className='leading-3 font-semibold'>EDUCATORS</span>
				<p>
					<a href='#link' className='link link-hover leading-3 text-sm'>
						Overview
					</a>
				</p>
				<p>
					<a href='#link' className='link link-hover leading-3 text-sm'>
						Elementary
					</a>
				</p>
				<a href='#link' className='link link-hover leading-3 text-sm'>
					Middle School
				</a>
				<p>
					<a href='#link' className='link link-hover leading-3 text-sm'>
						High School
					</a>
				</p>
				<p>
					<a href='#link' className='link link-hover leading-3 text-sm'>
						AP CS Courses
					</a>
				</p>
				<p>
					<a href='#link' className='link link-hover leading-3 text-sm'>
						PD
					</a>
				</p>
				<p>
					<a href='#link' className='link link-hover leading-3 text-sm'>
						Hour of Code
					</a>
				</p>
				<p>
					<a href='#link' className='link link-hover leading-3 text-sm'>
						STEM Projects
					</a>
				</p>
				<p>
					<a href='#link' className='link link-hover leading-3 text-sm'>
						Request a Quote
					</a>
				</p>
			</div>
			<div className='hidden md:block'>
				<span className='leading-3 font-semibold'>RESOURCES</span>
				<p>
					<a href='#link' className='link link-hover leading-3 text-sm'>
						Blog
					</a>
				</p>
				<p>
					<a href='#link' className='link link-hover leading-3 text-sm'>
						About Us
					</a>
				</p>
				<p>
					<a href='#link' className='link link-hover leading-3 text-sm'>
						Press
					</a>
				</p>
				<p>
					<a href='#link' className='link link-hover leading-3 text-sm'>
						Jobs
					</a>
				</p>
				<p>
					<a href='#link' className='link link-hover leading-3 text-sm'>
						Mobile Apps
					</a>
				</p>
				<p>
					<a href='#link' className='link link-hover leading-3 text-sm'>
						Coding eBooks
					</a>
				</p>
				<p>
					<a href='#link' className='link link-hover leading-3 text-sm'>
						Forum
					</a>
				</p>
				<p>
					<a href='#link' className='link link-hover leading-3 text-sm'>
						Store
					</a>
				</p>
				<p>
					<a href='#link' className='link link-hover leading-3 text-sm'>
						CS Authors
					</a>
				</p>
			</div>
			<div className='hidden md:block'>
				<span className='leading-3 font-semibold'>PARENTS</span>
				<p>
					<a href='#link' className='link link-hover leading-3 text-sm'>
						Overview
					</a>
				</p>
				<p>
					<a href='#link' className='link link-hover leading-3 text-sm'>
						Give a Gift
					</a>
				</p>
				<p>
					<a href='#link' className='link link-hover leading-3 text-sm'>
						Redeem
					</a>
				</p>
				<p>
					<a href='#link' className='link link-hover leading-3 text-sm'>
						Why Coding
					</a>
				</p>
				<p>
					<a href='#link' className='link link-hover leading-3 text-sm'>
						Featured Makers
					</a>
				</p>
			</div>
		</footer>
	);
};

export default Footer;
