import React from 'react';
import styles from './TutorialsCard.css';

const TutorialsCard = ({ tutorial }) => {
  return (
    <div className={styles.tutorialCard}>
      <div className={styles.tutorialCardText}>
        <h3 className='mb-2'>{tutorial.title}</h3>
        <p className='px-6'>{tutorial.description}</p>
      </div>
    </div>
  );
};

export default TutorialsCard;
