import axios from '../api/axios';

export const SET_ALERT = 'scratch-gui/auth/SET_ALERT';
export const REMOVE_ALERT = 'scratch-gui/auth/REMOVE_ALERT';
export const LOGIN_SUCCESS = 'scratch-gui/auth/LOGIN_SUCCESS';
export const AUTH_LOADING = 'scratch-gui/auth/AUTH_LOADING';
export const LOGIN_FAIL = 'scratch-gui/auth/LOGIN_FAIL';
export const USER_LOADED = 'scratch-gui/auth/USER_LOADED';
export const LOAD_FAIL = 'scratch-gui/auth/LOAD_FAIL';
export const LOGOUT = 'scratch-gui/auth/LOGOUT';
export const REGISTER_SUCCESS = 'scratch-gui/auth/REGISTER_SUCCESS';
export const REGISTER_FAIL = 'scratch-gui/auth/REGISTER_FAIL';
export const VERIFICATION_SUCCESS = 'scratch-gui/auth/VERIFICATION_SUCCESS';
export const VERIFICATION_FAIL = 'scratch-gui/auth/VERIFICATION_FAIL';
export const TOKEN_KEY = 'token';

const authInitialState = {
	token: localStorage.getItem(TOKEN_KEY),
	isAuthenticated: null,
	user: {},
	verified: null,
	loading: null,
	error: null,
};

const registerReducer = function (state, action) {
	if (typeof state === 'undefined') state = authInitialState;

	const { type, payload } = action;
	switch (type) {
		case AUTH_LOADING:
			return {
				...state,
				loading: true,
			};
		case USER_LOADED:
		case LOGIN_SUCCESS:
			if (type === LOGIN_SUCCESS) localStorage.setItem(TOKEN_KEY, payload.token);
			return {
				...state,
				...payload,
				isAuthenticated: true,
				loading: false,
				verified: payload.confrimed,
			};
		case REGISTER_SUCCESS:
			localStorage.setItem(TOKEN_KEY, payload.token);
			return {
				...state,
				...payload,
				isAuthenticated: true,
				loading: false,
			};
		case VERIFICATION_SUCCESS:
			localStorage.removeItem(TOKEN_KEY);
			return {
				...state,
				isAuthenticated: false,
				loading: false,
				verified: true,
			};
		case VERIFICATION_FAIL:
			return {
				...state,
				isAuthenticated: false,
				loading: false,
				verified: false,
			};
		case REGISTER_FAIL:
			return {
				...state,
				error: payload,
				loading: false
			}
		case LOGIN_FAIL:
			return {
				...state,
				error: payload,
				loading: false
			}
		case LOAD_FAIL:
		case LOGOUT:
			localStorage.removeItem(TOKEN_KEY);
			return {
				...state,
				...payload,
				isAuthenticated: false,
				loading: false,
				verified: null,
			};

		default:
			return state;
	}
};

const setAuthToken = token => {
	if (token) {
		axios.defaults.headers.common['x-auth-token'] = token;
	} else {
		delete axios.defaults.headers.common['x-auth-token'];
	}
};

const register = registerData => async dispatch => {
	try {
		dispatch({
			type: AUTH_LOADING,
		});
		const res = await axios.post(
			'/api/auth/user/register',
			{ ...registerData },
			{
				headers: {
					'Content-Type': 'application/json',
				},
			}
		);
		dispatch({
			type: REGISTER_SUCCESS,
			payload: res.data,
		});
		dispatch(loadUser());
	} catch (err) {
		console.log(err);
		if (!err.response.data.errors) {
		} else {
		}
		dispatch({
			type: REGISTER_FAIL,
			payload: err
		});
	}
};

const login =
	({ email, password }) =>
	async dispatch => {
		try {
			dispatch({
				type: AUTH_LOADING,
			});
			const res = await axios.post(
				'/api/auth/user/login',
				{ email, password },
				{
					headers: {
						'Content-Type': 'application/json',
					},
				}
			);
			dispatch({
				type: LOGIN_SUCCESS,
				payload: res.data,
			});
			dispatch(loadUser());
		} catch (err) {
			if (!err.response.data.errors) {
			} else {
				console.log(err.response.data.errors);
			}
			dispatch({
				type: LOGIN_FAIL,
				payload: err.response.data.errors
			});
		}
	};

const googleLogin =
	({ code }) =>
	async dispatch => {
		try {
			dispatch({
				type: AUTH_LOADING,
			});
			const res = await axios.post(
				'/api/auth/google/login',
				{ googleAccessToken: code },
				{
					headers: {
						'Content-Type': 'application/json',
					},
				}
			);
			dispatch({
				type: LOGIN_SUCCESS,
				payload: res.data,
			});
			dispatch(loadUser());
		} catch (err) {
			if (!err) {
			} else {
				console.log(err);
			}
			dispatch({
				type: LOGIN_FAIL,
			});
		}
	};

const googleRegister =
	({ code }) =>
	async dispatch => {
		try {
			dispatch({
				type: AUTH_LOADING,
			});
			const res = await axios.post(
				'/api/auth/google/register',
				{ googleAccessToken: code },
				{
					headers: {
						'Content-Type': 'application/json',
					},
				}
			);
			dispatch({
				type: REGISTER_SUCCESS,
				payload: res.data,
			});
			dispatch(loadUser());
		} catch (err) {
			if (!err) {
			} else {
				console.log(err);
			}
			dispatch({
				type: REGISTER_FAIL,
			});
		}
	};

const forgot = email => async dispatch => {
	const params = { email };
	try {
		await axios.post('/api/auth/user/forgot', params, {
			headers: {
				'content-type': 'application/json',
			},
		});
		window.alert("Link sent to your registered email!")
	} catch (err) {
		console.log(err);
	}
};

const updatePassword = ({ verificationToken, password }) => async dispatch => {
	console.log('run');
	
	try {
		await axios.patch('/api/auth/user/update/password', { verificationToken, password }, {
			headers: {
				'content-type': 'application/json',
			},
		});
		
	} catch (err) {
		console.log(err);
	}
};

const loadUser = () => async dispatch => {
	if (localStorage.token) {
		setAuthToken(localStorage.token);
	}
	try {
		const res = await axios.get('/api/auth/user/');
		dispatch({
			type: USER_LOADED,
			payload: res.data,
		});
	} catch (err) {
		if (!err.response.data.errors) {
		} else {
			if (err.response.status === 401) return;
		}
		dispatch({
			type: LOAD_FAIL,
		});
	}
};

const verify = id => async dispatch => {
	try {
		const res = await axios.get(`/api/auth/confirm/${id}`);
		dispatch({
			type: VERIFICATION_SUCCESS,
			payload: res.data,
		});
	} catch (err) {
		dispatch({
			type: VERIFICATION_FAIL,
			payload: null,
		});
	}
};

const logout = () => dispatch => {
	dispatch({ type: LOGOUT, payload: null });
};

export {
	registerReducer as default,
	authInitialState,
	register,
	login,
	googleLogin,
	googleRegister,
	loadUser,
	forgot,
	verify,
	logout,
	updatePassword
};
