exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Loader_loader_3yx7I {\r\n    border: 5px solid #f3f3f3;\r\n    border-top: 5px solid #fa8a8a;\r\n    border-radius: 50%;\r\n    width: 80px;\r\n    height: 80px;\r\n    -webkit-animation: Loader_spin_2wKBF 1s linear infinite;\r\n            animation: Loader_spin_2wKBF 1s linear infinite;\r\n    margin-inline: auto;\r\n    margin-bottom: 1rem;\r\n}\r\n\r\n@-webkit-keyframes Loader_spin_2wKBF {\r\n    0% {\r\n      -webkit-transform: rotate(0deg);\r\n              transform: rotate(0deg);\r\n    }\r\n    100% {\r\n      -webkit-transform: rotate(360deg);\r\n              transform: rotate(360deg);\r\n    }\r\n}\r\n\r\n@keyframes Loader_spin_2wKBF {\r\n    0% {\r\n      -webkit-transform: rotate(0deg);\r\n              transform: rotate(0deg);\r\n    }\r\n    100% {\r\n      -webkit-transform: rotate(360deg);\r\n              transform: rotate(360deg);\r\n    }\r\n}", ""]);

// exports
exports.locals = {
	"loader": "Loader_loader_3yx7I",
	"spin": "Loader_spin_2wKBF"
};