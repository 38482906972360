import React, { useEffect, useState } from 'react';
import PageHeader from '../../../shared/PageHeader/PageHeader';
import TutorialsCard from '../TutorialsCard/TutorialsCard';
import styles from './TutorialsCards.css';

const TutorialsCards = () => {
  const [tutorials, setTutorials] = useState([]);

  useEffect(() => {
    fetch(
      'https://raw.githubusercontent.com/azalamin/sass-practice/main/tutorials.json'
    )
      .then(res => res.json())
      .then(data => setTutorials(data));
  }, []);

  return (
    <section className={styles.tutorials}>
      <div className='px-6 lg:px-12 pt-14 lg:flex justify-between'>
        <PageHeader />
        <div className={`${styles.filterContainer} mt-6 lg:mt-0`}>
          <select className={`select ${styles.filter}`}>
            <option disabled selected>
              Filters
            </option>
            <option>Homer</option>
            <option>Marge</option>
            <option>Bart</option>
            <option>Lisa</option>
            <option>Maggie</option>
          </select>
        </div>
      </div>
      <div className='px-6 lg:px-12 mt-16'>
        <div
          className={`${styles.tutorialsCards} grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-10 lg:px-16 px-6`}
        >
          {tutorials.map((tutorial, index) => (
            <TutorialsCard key={index} tutorial={tutorial} />
          ))}
        </div>
        <div className='py-20 text-center'>
          <button className={styles.nextPage}>{'Next Page  >'}</button>
        </div>
      </div>
    </section>
  );
};

export default TutorialsCards;
