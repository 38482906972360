export const STEM_DATA = [
	{
        id: 1,
        value: '1',
		name: "My Solar System",
		label: "My Solar System",
		url: "https://phet.colorado.edu/sims/html/my-solar-system/latest/my-solar-system_en.html",
		timer: 15000
	}, {
        id: 2,
        value: '2',
		name: "Number Play",
		label: "Number Play",
		url: "https://phet.colorado.edu/sims/html/number-play/latest/number-play_en.html",
		timer: 15000
	}, {
        id: 3,
        value: '3',
		name: "Density",
		label: "Density",
		url: "https://phet.colorado.edu/sims/html/density/latest/density_en.html",
		timer: 15000
	}, {
        id: 4,
        value: '4',
		name: "Green House Effect",
		label: "Green House Effect",
		url: "https://phet.colorado.edu/sims/cheerpj/greenhouse/latest/greenhouse.html?simulation=greenhouse",
		timer: 30000
	}, {
        id: 5,
        value: '5',
		name: "Ratio and Proportion",
		label: "Ratio and Proportion",
		url: "https://phet.colorado.edu/sims/html/ratio-and-proportion/latest/ratio-and-proportion_en.html",
		timer: 15000
	},
	{
        id: 6,
        value: '6',
		name: "Natural Selection",
		label: "Natural Selection",
		url: "https://phet.colorado.edu/sims/html/natural-selection/latest/natural-selection_en.html",
		timer: 15000
	}, {
        id: 7,
        value: '7',
		name: "Number Line Integer",
		label: "Number Line Integer",
		url: "https://phet.colorado.edu/sims/html/number-line-integers/latest/number-line-integers_en.html",
		timer: 15000
	}, {
        id: 8,
        value: '8',
		name: "Gravity Basics",
		label: "Gravity Basics",
		url: "https://phet.colorado.edu/sims/html/gravity-force-lab-basics/latest/gravity-force-lab-basics_en.html",
		timer: 15000
	}, {
        id: 9,
        value: '9',
		name: "Gases",
		label: "Gases",
		url: "https://phet.colorado.edu/sims/html/gases-intro/latest/gases-intro_en.html",
		timer: 15000
	}, {
        id: 10,
        value: '10',
		name: "Energy Forms and Changes",
		label: "Energy Forms and Changes",
		url: "https://phet.colorado.edu/sims/html/energy-forms-and-changes/latest/energy-forms-and-changes_en.html",
		timer: 15000
	}, {
        id: 11,
        value: '11',
		name: "Fractions Intro",
		label: "Fractions Intro",
		url: "https://phet.colorado.edu/sims/html/fractions-intro/latest/fractions-intro_en.html",
		timer: 15000
	}, {
        id: 12,
        value: '12',
		name: "Fractions Mixed numbers",
		label: "Fractions Mixed numbers",
		url: "https://phet.colorado.edu/sims/html/fractions-mixed-numbers/latest/fractions-mixed-numbers_en.html",
		timer: 15000
	}, {
        id: 13,
        value: '13',
		name: "Fraction Equility",
		label: "Fraction Equility",
		url: "https://phet.colorado.edu/sims/html/fractions-equality/latest/fractions-equality_en.html",
		timer: 15000
	}, {
        id: 14,
        value: '14',
		name: "Build a Fraction",
		label: "Build a Fraction",
		url: "https://phet.colorado.edu/sims/html/build-a-fraction/latest/build-a-fraction_en.html",
		timer: 15000
	}, {
        id: 15,
        value: '15',
		name: "Equality Explorer",
		label: "Equality Explorer",
		url: "https://phet.colorado.edu/sims/html/equality-explorer-basics/latest/equality-explorer-basics_en.html",
		timer: 15000
	}, {
        id: 16,
        value: '16',
		name: "Area Model Decimals",
		label: "Area Model Decimals",
		url: "https://phet.colorado.edu/sims/html/area-model-decimals/latest/area-model-decimals_en.html",
		timer: 15000
	}, {
        id: 17,
        value: '17',
		name: "Area model Multiplication",
		label: "Area model Multiplication",
		url: "https://phet.colorado.edu/sims/html/area-model-multiplication/latest/area-model-multiplication_en.html",
		timer: 15000
	}, {
        id: 18,
        value: '18',
		name: "Expresion Exchange",
		label: "Expresion Exchange",
		url: "https://phet.colorado.edu/sims/html/expression-exchange/latest/expression-exchange_en.html",
		timer: 15000
	}, {
        id: 19,
        value: '19',
		name: "Unit Rates",
		label: "Unit Rates",
		url: "https://phet.colorado.edu/sims/html/unit-rates/latest/unit-rates_en.html",
		timer: 15000
	}, {
        id: 20,
        value: '20',
		name: "Make a TEN",
		label: "Make a TEN",
		url: "https://phet.colorado.edu/sims/html/make-a-ten/latest/make-a-ten_en.html",
		timer: 15000
	}, {
        id: 21,
        value: '21',
		name: "States of Matter",
		label: "States of Matter",
		url: "https://phet.colorado.edu/sims/html/states-of-matter-basics/latest/states-of-matter-basics_en.html",
		timer: 15000
	}, {
        id: 22,
        value: '22',
		name: "Gravity and Orbits",
		label: "Gravity and Orbits",
		url: "https://phet.colorado.edu/sims/html/gravity-and-orbits/latest/gravity-and-orbits_en.html",
		timer: 15000
	}, {
        id: 23,
        value: '23',
		name: "Bending Lights",
		label: "Bending Lights",
		url: "https://phet.colorado.edu/sims/html/bending-light/latest/bending-light_en.html",
		timer: 15000
	}, {
        id: 24,
        value: '24',
		name: "Energy Basics",
		label: "Energy Basics",
		url: "https://phet.colorado.edu/sims/html/energy-skate-park-basics/latest/energy-skate-park-basics_en.html",
		timer: 15000
	}, {
        id: 25,
        value: '25',
		name: "Area Builder",
		label: "Area Builder",
		url: "https://phet.colorado.edu/sims/html/area-builder/latest/area-builder_en.html",
		timer: 15000
	}, {
        id: 26,
        value: '26',
		name: "Fraction Matcher",
		label: "Fraction Matcher",
		url: "https://phet.colorado.edu/sims/html/fraction-matcher/latest/fraction-matcher_en.html",
		timer: 15000
	}, {
        id: 27,
        value: '27',
		name: "Balancing",
		label: "Balancing",
		url: "https://phet.colorado.edu/sims/html/balancing-act/latest/balancing-act_en.html",
		timer: 15000
	}, {
        id: 28,
        value: '28',
		name: "Friction",
		label: "Friction",
		url: "https://phet.colorado.edu/sims/html/friction/latest/friction_en.html",
		timer: 15000
	}, {
        id: 29,
        value: '29',
		name: "Force and Motion Basics",
		label: "Force and Motion Basics",
		url: "https://phet.colorado.edu/sims/html/forces-and-motion-basics/latest/forces-and-motion-basics_en.html",
		timer: 15000
	}, {
        id: 30,
        value: '30',
		name: "Concentration",
		label: "Concentration",
		url: "https://phet.colorado.edu/sims/html/concentration/latest/concentration_en.html",
		timer: 15000
	}, {
        id: 31,
        value: '31',
		name: "Balloon and Static Electricity",
		label: "Balloon and Static Electricity",
		url: "https://phet.colorado.edu/sims/html/balloons-and-static-electricity/latest/balloons-and-static-electricity_en.html",
		timer: 15000
	}, {
        id: 32,
        value: '32',
		name: "Arthematics",
		label: "Arthematics",
		url: "https://phet.colorado.edu/sims/html/arithmetic/latest/arithmetic_en.html",
		timer: 15000
	}, {
        id: 33,
        value: '33',
		name: "Mean",
		label: "Mean",
		url: "https://phet.colorado.edu/sims/html/mean-share-and-balance/latest/mean-share-and-balance_en.html",
		timer: 15000
	}, {
        id: 34,
        value: '34',
		name: "Center and Variability",
		label: "Center and Variability",
		url: "https://phet.colorado.edu/sims/html/center-and-variability/latest/center-and-variability_en.html",
		timer: 15000
	}, {
        id: 35,
        value: '35',
		name: "Density",
		label: "Density",
		url: "https://phet.colorado.edu/sims/html/density/latest/density_en.html",
		timer: 15000
	}, {
        id: 36,
        value: '36',
		name: "Ratio and Proportion",
		label: "Ratio and Proportion",
		url: "https://phet.colorado.edu/sims/html/ratio-and-proportion/latest/ratio-and-proportion_en.html",
		timer: 15000
	}, {
        id: 37,
        value: '37',
		name: "Collision",
		label: "Collision",
		url: "https://phet.colorado.edu/sims/html/collision-lab/latest/collision-lab_en.html",
		timer: 15000
	}, {
        id: 38,
        value: '38',
		name: "Build a Molecule",
		label: "Build a Molecule",
		url: "https://phet.colorado.edu/sims/html/build-a-molecule/latest/build-a-molecule_en.html",
		timer: 15000
	}, {
        id: 39,
        value: '39',
		name: "Gravity Force Lab",
		label: "Gravity Force Lab",
		url: "https://phet.colorado.edu/sims/html/gravity-force-lab-basics/latest/gravity-force-lab-basics_en.html",
		timer: 15000
	}, {
        id: 40,
        value: '40',
		name: "Diffusion",
		label: "Diffusion",
		url: "https://phet.colorado.edu/sims/html/diffusion/latest/diffusion_en.html",
		timer: 15000
	}, {
        id: 41,
        value: '41',
		name: "Black Body Spectrum",
		label: "Black Body Spectrum",
		url: "https://phet.colorado.edu/sims/html/blackbody-spectrum/latest/blackbody-spectrum_en.html",
		timer: 15000
	}, {
        id: 42,
        value: '42',
		name: "Energy Forms and Changes",
		label: "Energy Forms and Changes",
		url: "https://phet.colorado.edu/sims/html/energy-forms-and-changes/latest/energy-forms-and-changes_en.html",
		timer: 15000
	}, {
        id: 43,
        value: '43',
		name: "Quadratic Functions",
		label: "Quadratic Functions",
		url: "https://phet.colorado.edu/sims/html/graphing-quadratics/latest/graphing-quadratics_en.html",
		timer: 15000
	}, {
        id: 44,
        value: '44',
		name: "Equality in two Variables",
		label: "Equality in two Variables",
		url: "https://phet.colorado.edu/sims/html/equality-explorer-two-variables/latest/equality-explorer-two-variables_en.html",
		timer: 15000
	}, {
        id: 45,
        value: '45',
		name: "Area Model Algebra",
		label: "Area Model Algebra",
		url: "https://phet.colorado.edu/sims/html/area-model-algebra/latest/area-model-algebra_en.html",
		timer: 15000
	}, {
        id: 46,
        value: '46',
		name: "Capacitor Lab",
		label: "Capacitor Lab",
		url: "https://phet.colorado.edu/sims/html/capacitor-lab-basics/latest/capacitor-lab-basics_en.html",
		timer: 15000
	}, {
        id: 47,
        value: '47',
		name: "Slope and Intercept",
		label: "Slope and Intercept",
		url: "https://phet.colorado.edu/sims/html/graphing-slope-intercept/latest/graphing-slope-intercept_en.html",
		timer: 15000
	}, {
        id: 48,
        value: '48',
		name: "Molecules and Light",
		label: "Molecules and Light",
		url: "https://phet.colorado.edu/sims/html/molecules-and-light/latest/molecules-and-light_en.html",
		timer: 15000
	}, {
        id: 49,
        value: '49',
		name: "Molecules Shapes",
		label: "Molecules Shapes",
		url: "https://phet.colorado.edu/sims/html/molecule-shapes-basics/latest/molecule-shapes-basics_en.html",
		timer: 15000
	}, {
        id: 50,
        value: '50',
		name: "Reactants & Products",
		label: "Reactants & Products",
		url: "https://phet.colorado.edu/sims/html/reactants-products-and-leftovers/latest/reactants-products-and-leftovers_en.html",
		timer: 15000
	}, {
        id: 51,
        value: '51',
		name: "Faraday Law",
		label: "Faraday Law",
		url: "https://phet.colorado.edu/sims/html/faradays-law/latest/faradays-law_en.html",
		timer: 15000
	}, {
        id: 52,
        value: '52',
		name: "PH Basics",
		label: "PH Basics",
		url: "https://phet.colorado.edu/sims/html/ph-scale-basics/latest/ph-scale-basics_en.html",
		timer: 15000
	}, {
        id: 53,
        value: '53',
		name: "Wave on String",
		label: "Wave on String",
		url: "https://phet.colorado.edu/sims/html/wave-on-a-string/latest/wave-on-a-string_en.html",
		timer: 15000
	}, {
        id: 54,
        value: '54',
		name: "Fraction Matcher",
		label: "Fraction Matcher",
		url: "https://phet.colorado.edu/sims/html/fraction-matcher/latest/fraction-matcher_en.html",
		timer: 15000
	}, {
        id: 55,
        value: '55',
		name: "Pressure",
		label: "Pressure",
		url: "https://phet.colorado.edu/sims/html/under-pressure/latest/under-pressure_en.html",
		timer: 15000
	}, {
        id: 56,
        value: '56',
		name: "Concentration",
		label: "Concentration",
		url: "https://phet.colorado.edu/sims/html/concentration/latest/concentration_en.html",
		timer: 15000
	}, {
        id: 57,
        value: '57',
		name: "Ohms Law",
		label: "Ohms Law",
		url: "https://phet.colorado.edu/sims/html/ohms-law/latest/ohms-law_en.html",
		timer: 15000
	}, {
        id: 58,
        value: '58',
		name: "Resistance in Wires",
		label: "Resistance in Wires",
		url: "https://phet.colorado.edu/sims/html/resistance-in-a-wire/latest/resistance-in-a-wire_en.html",
		timer: 15000
	}, {
        id: 59,
        value: '59',
		name: "Build an Atom",
		label: "Build an Atom",
		url: "https://phet.colorado.edu/sims/html/build-an-atom/latest/build-an-atom_en.html",
		timer: 15000
	}, {
        id: 60,
        value: '60',
		name: "Geometric Optics Basics",
		label: "Geometric Optics Basics",
		url: "https://phet.colorado.edu/sims/html/geometric-optics-basics/latest/geometric-optics-basics_en.html",
		timer: 15000
	}, {
        id: 61,
        value: '61',
		name: "Wave Intro",
		label: "Wave Intro",
		url: "https://phet.colorado.edu/sims/html/waves-intro/latest/waves-intro_en.html",
		timer: 15000
	}, {
        id: 62,
        value: '62',
		name: "Wave Interference",
		label: "Wave Interference",
		url: "https://phet.colorado.edu/sims/html/wave-interference/latest/wave-interference_en.html",
		timer: 15000
	}, {
        id: 63,
        value: '63',
		name: "Pendulum",
		label: "Pendulum",
		url: "https://phet.colorado.edu/sims/html/pendulum-lab/latest/pendulum-lab_en.html",
		timer: 15000
	}, {
        id: 64,
        value: '64',
		name: "Projectile Motion",
		label: "Projectile Motion",
		url: "https://phet.colorado.edu/sims/html/projectile-motion/latest/projectile-motion_en.html",
		timer: 15000
	}, {
        id: 65,
        value: '65',
		name: "Masses and Springs",
		label: "Masses and Springs",
		url: "https://phet.colorado.edu/sims/html/masses-and-springs-basics/latest/masses-and-springs-basics_en.html",
		timer: 15000
	}, {
        id: 66,
        value: '66',
		name: "Circuit Construction Kit",
		label: "Circuit Construction Kit",
		url: "https://phet.colorado.edu/sims/html/circuit-construction-kit-dc-virtual-lab/latest/circuit-construction-kit-dc-virtual-lab_en.html",
		timer: 15000
	}, {
        id: 67,
        value: '67',
		name: "Circuit Construction DC",
		label: "Circuit Construction DC",
		url: "https://phet.colorado.edu/sims/html/circuit-construction-kit-dc/latest/circuit-construction-kit-dc_en.html",
		timer: 15000
	}, {
        id: 68,
        value: '68',
		name: "Function Builder",
		label: "Function Builder",
		url: "https://phet.colorado.edu/sims/html/function-builder/latest/function-builder_en.html",
		timer: 15000
	}, {
        id: 69,
        value: '69',
		name: "Color Vision",
		label: "Color Vision",
		url: "https://phet.colorado.edu/sims/html/color-vision/latest/color-vision_en.html",
		timer: 15000
	}, {
        id: 70,
        value: '70',
		name: "Modes",
		label: "Modes",
		url: "https://phet.colorado.edu/sims/html/normal-modes/latest/normal-modes_en.html",
		timer: 15000
	}, {
        id: 71,
        value: '71',
		name: "Circuit Construction AC",
		label: "Circuit Construction AC",
		url: "https://phet.colorado.edu/sims/html/circuit-construction-kit-ac-virtual-lab/latest/circuit-construction-kit-ac-virtual-lab_en.html",
		timer: 15000
	}, {
        id: 72,
        value: '72',
		name: "Vector Addition Equation",
		label: "Vector Addition Equation",
		url: "https://phet.colorado.edu/sims/html/vector-addition-equations/latest/vector-addition-equations_en.html",
		timer: 15000
	}, {
        id: 73,
        value: '73',
		name: "Vector Addition Basics",
		label: "Vector Addition Basics",
		url: "https://phet.colorado.edu/sims/html/vector-addition/latest/vector-addition_en.html",
		timer: 15000
	}, {
        id: 74,
        value: '74',
		name: "Curve Fitting",
		label: "Curve Fitting",
		url: "https://phet.colorado.edu/sims/html/curve-fitting/latest/curve-fitting_en.html",
		timer: 15000
	}, {
        id: 75,
        value: '75',
		name: "Gas Properties",
		label: "Gas Properties",
		url: "https://phet.colorado.edu/sims/html/gas-properties/latest/gas-properties_en.html",
		timer: 15000
	}, {
        id: 76,
        value: '76',
		name: "Coulombs Law",
		label: "Coulombs Law",
		url: "https://phet.colorado.edu/sims/html/coulombs-law/latest/coulombs-law_en.html",
		timer: 15000
	}, {
        id: 77,
        value: '77',
		name: "Graphing Quadratics",
		label: "Graphing Quadratics",
		url: "https://phet.colorado.edu/sims/html/graphing-quadratics/latest/graphing-quadratics_en.html",
		timer: 15000
	}, {
        id: 78,
        value: '78',
		name: "Gene Expression",
		label: "Gene Expression",
		url: "https://phet.colorado.edu/sims/html/gene-expression-essentials/latest/gene-expression-essentials_en.html",
		timer: 15000
	}, {
        id: 79,
        value: '79',
		name: "Molecule Polarity",
		label: "Molecule Polarity",
		url: "https://phet.colorado.edu/sims/html/molecule-polarity/latest/molecule-polarity_en.html",
		timer: 15000
	}, {
        id: 80,
        value: '80',
		name: "States of Matter Advance",
		label: "States of Matter Advance",
		url: "https://phet.colorado.edu/sims/html/states-of-matter/latest/states-of-matter_en.html",
		timer: 15000
	}, {
        id: 81,
        value: '81',
		name: "Atom Interactions",
		label: "Atom Interactions",
		url: "https://phet.colorado.edu/sims/html/atomic-interactions/latest/atomic-interactions_en.html",
		timer: 15000
	}, {
        id: 82,
        value: '82',
		name: "Charges and Fields",
		label: "Charges and Fields",
		url: "https://phet.colorado.edu/sims/html/charges-and-fields/latest/charges-and-fields_en.html",
		timer: 15000
	}, {
        id: 83,
        value: '83',
		name: "Rutherford Scattering",
		label: "Rutherford Scattering",
		url: "https://phet.colorado.edu/sims/html/rutherford-scattering/latest/rutherford-scattering_en.html",
		timer: 15000
	}, {
        id: 84,
        value: '84',
		name: "Isotopes and Atomic Mass",
		label: "Isotopes and Atomic Mass",
		url: "https://phet.colorado.edu/sims/html/isotopes-and-atomic-mass/latest/isotopes-and-atomic-mass_en.html",
		timer: 15000
	}, {
        id: 85,
        value: '85',
		name: "Neuron",
		label: "Neuron",
		url: "https://phet.colorado.edu/sims/html/neuron/latest/neuron_en.html",
		timer: 15000
	}, {
        id: 86,
        value: '86',
		name: "Trigonometry Tour",
		label: "Trigonometry Tour",
		url: "https://phet.colorado.edu/sims/html/trig-tour/latest/trig-tour_en.html",
		timer: 15000
	}, {
        id: 87,
        value: '87',
		name: "Bending of Light",
		label: "Bending of Light",
		url: "https://phet.colorado.edu/sims/html/bending-light/latest/bending-light_en.html",
		timer: 15000
	}, {
        id: 88,
        value: '88',
		name: "Hooks Law",
		label: "Hooks Law",
		url: "https://phet.colorado.edu/sims/html/hookes-law/latest/hookes-law_en.html",
		timer: 15000
	}, {
        id: 89,
        value: '89',
		name: "Light and Molecule",
		label: "Light and Molecule",
		url: "https://phet.colorado.edu/sims/html/molecules-and-light/latest/molecules-and-light_en.html",
		timer: 15000
	}, {
        id: 90,
        value: '90',
		name: "Balancing Chemical Equation",
		label: "Balancing Chemical Equation",
		url: "https://phet.colorado.edu/sims/html/balancing-chemical-equations/latest/balancing-chemical-equations_en.html",
		timer: 15000
	}, {
        id: 91,
        value: '91',
		name: "Lines in Graph",
		label: "Lines in Graph",
		url: "https://phet.colorado.edu/sims/html/graphing-lines/latest/graphing-lines_en.html",
		timer: 15000
	}, {
        id: 92,
        value: '92',
		name: "Acid Base Solutons",
		label: "Acid Base Solutons",
		url: "https://phet.colorado.edu/sims/html/acid-base-solutions/latest/acid-base-solutions_en.html",
		timer: 15000
	}, {
        id: 93,
        value: '93',
		name: "Beers Law",
		label: "Beers Law",
		url: "https://phet.colorado.edu/sims/html/beers-law-lab/latest/beers-law-lab_en.html",
		timer: 15000
	}, {
        id: 94,
        value: '94',
		name: "Molarity",
		label: "Molarity",
		url: "https://phet.colorado.edu/sims/html/molarity/latest/molarity_en.html",
		timer: 15000
	}, {
        id: 95,
        value: '95',
		name: "Regression in Least Squares",
		label: "Regression in Least Squares",
		url: "https://phet.colorado.edu/sims/html/least-squares-regression/latest/least-squares-regression_en.html",
		timer: 15000
	}, {
        id: 96,
        value: '96',
		name: "Plinko Probablity",
		label: "Plinko Probablity",
		url: "https://phet.colorado.edu/sims/html/plinko-probability/latest/plinko-probability_en.html",
		timer: 15000
	},
]