exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@tailwind base;\r\n@tailwind components;\r\n@tailwind utilities;\r\nhtml,\r\nbody,\r\n.index_app_2mqDO {\r\n\t/* probably unecessary, transitional until layout is refactored */\r\n\twidth: 100%;\r\n\theight: 100%;\r\n\tmargin: 0;\r\n\r\n\t/* Setting min height/width makes the UI scroll below those sizes */\r\n\tmin-width: 1024px;\r\n\tmin-height: 640px; /* Min height to fit sprite/backdrop button */\r\n}\r\n/* @todo: move globally? Safe / side FX, for blocks particularly? */\r\n* {\r\n\t-webkit-box-sizing: border-box;\r\n\t        box-sizing: border-box;\r\n}\r\n", ""]);

// exports
exports.locals = {
	"app": "index_app_2mqDO"
};