var escape = require("../../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Services_section_title_3ZwYi{text-align:center;margin-top:117px;color:#0c2032}.Services_change-maker-container_3FGEu{background:url(" + escape(require("../../../assets/change-maker-bg-left.svg")) + ");background-position:top left;background-repeat:no-repeat}.Services_bottom-service_g6aTY{background-image:url(" + escape(require("../../../assets/change-maker-bg-right.svg")) + ");background-position:center right;background-repeat:no-repeat}", ""]);

// exports
exports.locals = {
	"section_title": "Services_section_title_3ZwYi",
	"sectionTitle": "Services_section_title_3ZwYi",
	"change-maker-container": "Services_change-maker-container_3FGEu",
	"changeMakerContainer": "Services_change-maker-container_3FGEu",
	"bottom-service": "Services_bottom-service_g6aTY",
	"bottomService": "Services_bottom-service_g6aTY"
};