import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import BACK_ICON from "../../../assets/img/back_arrow_icon.svg";

const PhysicsExamples = ({EXAMPLES}) => {
    const [selected, setSelected] = useState(null)
  return (
    <div className="flex flex-wrap gap-8 items-center mt-[6vh]">  
        {EXAMPLES.map((game, i) => (
            <div className="bg-white rounded-xl overflow-hidden p-4 flex flex-col">
                <div className="w-full flex justify-center items-center bg-[#f5f5f5] mb-2">
                    <img src={game.img} alt="" className="h-[160px]" />
                </div>
                <h2 className="text-lg text-center mb-1">{game.title}</h2>
                <Button 
                    onClick={() => setSelected(i + 1)} 
                    variant="contained" 
                    style={{ 
                        background:"#3b5998", 
                        fontWeight:"600", 
                        color: "#FFFFFF", 
                        width: "100%", 
                        fontFamily: "Poppins", 
                        marginTop: "auto", 
                        fontSize: "0.65rem" 
                    }} 
                >
                    Open
                </Button>
            </div>
        ))}
        {selected && <>
            <iframe src={EXAMPLES[selected - 1].url} className='fixed w-full h-full -translate-x-1/2 -translate-y-1/2 top-[50%] left-[50%]' />  
            <button className="flex items-center gap-3 text-white font-semibold mr-auto mb-4 fixed top-10 left-6 z-10" onClick={() => setSelected(null)}><img src={BACK_ICON} alt="BACK" className="size-8" /> Back</button>  
        </>}
    </div>
  )
}

export default PhysicsExamples