var escape = require("../../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".TutorialsCard_tutorial-card_1iG7s{border-radius:44px;background-image:url(" + escape(require("../../../assets/tutorial-1.svg")) + ");background-position:center;background-size:cover;background-repeat:no-repeat;min-height:320px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:end;-webkit-align-items:flex-end;-ms-flex-align:end;align-items:flex-end}.TutorialsCard_tutorial-card_1iG7s .TutorialsCard_tutorial-card-text_1hzMs{border-radius:28px 28px 8px 8px;padding:20px 0px;background:#fff;-webkit-box-shadow:0px 4px 13px rgba(0,0,0,.04);box-shadow:0px 4px 13px rgba(0,0,0,.04)}.TutorialsCard_tutorial-card_1iG7s .TutorialsCard_tutorial-card-text_1hzMs h3{font-family:\"Karla\";font-style:normal;font-weight:700;font-size:18px;line-height:21px;text-align:center;color:#414141}.TutorialsCard_tutorial-card_1iG7s .TutorialsCard_tutorial-card-text_1hzMs p{font-family:\"Karla\";font-style:normal;font-weight:400;font-size:14px;line-height:16px;text-align:center;color:#0c2032}", ""]);

// exports
exports.locals = {
	"tutorial-card": "TutorialsCard_tutorial-card_1iG7s",
	"tutorialCard": "TutorialsCard_tutorial-card_1iG7s",
	"tutorial-card-text": "TutorialsCard_tutorial-card-text_1hzMs",
	"tutorialCardText": "TutorialsCard_tutorial-card-text_1hzMs"
};