import React from 'react'
import styles from './Loader.css'
import light from '../../assets/Final.gif'

const Loader = () => {
  return (
    <div 
      // className={styles.loader} 
      style={{width:'184px', marginInline: 'auto'}}
    ><img src={light} alt="" /></div>
  )
}

export default Loader