import React from 'react';
import robot from '../../../assets/robot-3.svg';
import CustomButton from '../../../shared/CustomButton/CustomButton';
import Navbar from '../../../shared/Navbar/Navbar';
import Login from '../../landing-page/Login/Login.jsx';
import Signup from '../../landing-page/Signup/Signup.jsx';
import styles from './ProfileHeader.css';

const ProfileHeader = () => {
	return (
		<>
			<header className={styles.dashboardHeader}>
				<Navbar></Navbar>
				{true && <Login />}
				{true && <Signup />}
			</header>
			<div className={`flex justify-center items-center mt-[-60px] ${styles.bannerContainer}`}>
				<div className='ml-auto z-10'>
					<div className={`text-center ml-[215px] ${styles.profile}`}>
						<img src={robot} alt='' />
					</div>
					<div className={`text-center ml-[200px] mt-2 ${styles.userInfo}`}>
						<h3>User name</h3>
						<p>Joined on 12.06.22</p>
					</div>
				</div>
				<div className='z-10 ml-auto mr-[200px] mt-[-90px]'>
					<CustomButton>+ Create Now</CustomButton>
				</div>
			</div>
		</>
	);
};

export default ProfileHeader;
