import React from 'react';
import critical from '../../../assets/critical.svg';
import innovation from '../../../assets/innovation.svg';
import pen from '../../../assets/logical-pen.svg';
import team from '../../../assets/logical-team.svg';
import setting from '../../../assets/logincal-setting.svg';
import styles from './Services.css';

const Services = () => {
  return (
    <>
      <section className={styles.changeMakerContainer}>
        <div className={`px-12 md:px-12 pb-10`}>
          <h2 className={`${styles.sectionTitle} mb-[5px]`}>
            Become a ChangeMaker
          </h2>
          <p className='text-center text-black mb-[200px]'>
            Learn, Tinker & Innovate to develop 21st Century Skills
          </p>
          <div className='flex flex-col items-center md:flex-row justify-center gap-20 mt-36'>
            <div className='relative'>
              <img
                className='w-[153px] left-[20%] absolute top-[-120px]'
                src={innovation}
                alt=''
              />
              <div className='mb-10 h-[203px] w-[247px] lg:mb-0 bg-[#E8FFDE] rounded-[30px] flex items-center justify-center'>
                <h5 className='text-center text-black'>Innovation</h5>
              </div>
            </div>
            <div className='relative'>
              <img
                className='w-[153px] left-[20%] absolute top-[-120px]'
                src={setting}
                alt=''
              />
              <div className='mb-10 h-[203px] w-[247px] lg:mb-0 bg-[#E8FFDE] rounded-[30px] flex items-center justify-center'>
                <div>
                  <h5 className='text-center text-black'>Logical</h5>
                  <h5 className='text-center text-black'>Reasoning</h5>
                </div>
              </div>
            </div>
            <div className='relative'>
              <img
                className='w-[153px] left-[20%] absolute top-[-120px]'
                src={pen}
                alt=''
              />
              <div className='mb-10 h-[203px] w-[247px] lg:mb-0 bg-[#E8FFDE] rounded-[30px] flex items-center justify-center'>
                <div>
                  <h5 className='text-center text-black'>Logical</h5>
                  <h5 className='text-center text-black'>Reasoning</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className={styles.bottomService}>
        <div className='flex flex-col items-center md:flex-row justify-center gap-20 mt-36 px-12 md:px-12 pb-28'>
          <div className='relative'>
            <img
              className='w-[153px] left-[20%] absolute top-[-120px]'
              src={team}
              alt=''
            />
            <div className='mb-10 h-[203px] w-[247px] lg:mb-0 bg-[#E8FFDE] rounded-[30px] flex items-center justify-center'>
              <div>
                <h5 className='text-center text-black'>Logical</h5>
                <h5 className='text-center text-black'>Reasoning</h5>
              </div>
            </div>
          </div>
          <div className='relative'>
            <img
              className='w-[153px] left-[20%] absolute top-[-120px]'
              src={critical}
              alt=''
            />
            <div className='mb-10 h-[203px] w-[247px] lg:mb-0 bg-[#E8FFDE] rounded-[30px] flex items-center justify-center'>
              <div>
                <h5 className='text-center text-black'>Critical</h5>
                <h5 className='text-center text-black'>Thinking</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
