import React from 'react'
import IframePage from './iframe-page.jsx'
import CodeLanding from '../components/code-ide/CodeLanding.js'

const Cpp = () => {
  return (
    <CodeLanding defaultLang={'cpp'} />
  )
}

export default Cpp