import React, { useEffect, useState } from 'react';
import IframePage from './iframe-page.jsx';
import Loader from '../shared/Loader/Loader.jsx';
import STEM_HEADER_ICON from '../assets/stem_header_icon.png'
import Overlay from '../shared/Overlay/Overlay.jsx';
import { STEM_DATA } from '../constants/stem-data.js';
import StemSelect from '../shared/StemSelect/StemSelect.jsx';
import { Box } from '@material-ui/core';
import OPEN_DRAWER from "../assets/openDrawer.svg";

export default function StemPhet() {
	const [isMounted, setIsMounted] = useState(false);
	const handleIframeLoad = () => {
		setIsMounted(true);
	};
	const [isLoading, setIsLoading] = useState(true);
	const [timer, setTimer] = useState(15000);
	const [isDrawerOpen, setIsDrawerOpen] = useState(true);
	const [selectedURL, setSelectedURL] = useState(
		'https://phet.colorado.edu/sims/html/geometric-optics-basics/latest/geometric-optics-basics_en.html'
	);
	const [selectStates, setSelectStates] = useState({
		primarySelect: '',
		middleSelect: {
			id: 60,
			value: '60',
			name: "Geometric Optics Basics",
			label: "Geometric Optics Basics",
			url: "https://phet.colorado.edu/sims/html/geometric-optics-basics/latest/geometric-optics-basics_en.html",
			timer: 15000
		},
		diplomaSelect: ''
	});
	const updateIframe = (e, select) => {
		setTimer(e.timer)
		
		setSelectedURL(STEM_DATA[Number(e.id - 1)].url)
		switch (select) {
			case '1':
				setSelectStates({
					primarySelect: e,
					middleSelect: {
						id: 0,
						value: '0',
						name: "Select",
						label: "Select",
						timer: 15000
					},
					diplomaSelect: {
						id: 0,
						value: '0',
						name: "Select",
						label: "Select",
						timer: 15000
					}
				})
				return;
			case '2':
				setSelectStates({
					primarySelect: {
						id: 0,
						value: '0',
						name: "Select",
						label: "Select",
						timer: 15000
					},
					middleSelect: e,
					diplomaSelect: {
						id: 0,
						value: '0',
						name: "Select",
						label: "Select",
						timer: 15000
					}
				})
				return;
			case '3':
				setSelectStates({
					primarySelect: {
						id: 0,
						value: '0',
						name: "Select",
						label: "Select",
						timer: 15000
					},
					middleSelect: {
						id: 0,
						value: '0',
						name: "Select",
						label: "Select",
						timer: 15000
					},
					diplomaSelect: e
				})
				return;
			default:
				return;
		}
	};

	useEffect(() => {
		setIsLoading(true);
		const timeoutId = setTimeout(() => {
		  setIsLoading(false);
		}, timer);
	
		return () => clearTimeout(timeoutId);
	}, [selectStates]); 
	
	const [dots, setDots] = useState([]);
	useEffect(() => {
		const timeoutId = setTimeout(() => {
			if(dots.length < 3){
				setDots([...dots, 0])
			} else {
				setDots([]);
			}
		}, 500);
		if(!isLoading) clearTimeout(timeoutId)
	
		return () => clearTimeout(timeoutId);
	}, [dots, isLoading]); 

	useEffect(() => {
		const drawerTimeout = setTimeout(() => {
			if(!isLoading){
				setIsDrawerOpen(false)
			} 
		}, 3500);
	
		return () => clearTimeout(drawerTimeout);
	}, []); 

	const closeDrawer = () => {
		const drawerTimeout = setTimeout(() => {
			setIsDrawerOpen(false)
		}, 5500);
	
		return () => clearTimeout(drawerTimeout);
	}
	
	return (
		<IframePage
			iframe={<iframe src={selectedURL} onLoad={handleIframeLoad} width='100%' height='100%' seamless title='STEM' />}
			addon={
				<>
					<Box
						className={`!justify-between ${isDrawerOpen ? '!pl-6' : 'pl-0'}`}
						sx={{
							position: 'absolute',
							top: 0,
							left: 0,
							right: isDrawerOpen ? 0 : "100%",
							width: isDrawerOpen ? "100%" : 0,
							minHeight: "72px",
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							background: '#3b5998',
							padding: isDrawerOpen ? '10px' : '0px',
							transition: "0.5s ease-in-out"
						}}
						onMouseEnter={()=>setIsDrawerOpen(true)}
						onMouseOver={()=>setIsDrawerOpen(true)}
						onMouseLeave={()=>closeDrawer()}
					>
						<img src={STEM_HEADER_ICON} alt="" style={{ width: '145px' }} />
						{isDrawerOpen ? <div className={`flex gap-3 flex-wrap md:flex-nowrap`} style={{ top: 8, right: 30, position: 'absolute' }}>
							<div>
								<p className='text-xs text-white font-semibold'>Primary - Grades 3-5</p>
								<StemSelect 
									defaultValue={selectStates.primarySelect} 
									value={selectStates.primarySelect} 
									options={STEM_DATA.slice(0, 32)} 
									onSelectChange={(e) => updateIframe(e, "1")} 
								/>
							</div>
							<div>
								<p className='text-xs text-white font-semibold'>Middle Year - Grades 6-8</p>
								<StemSelect 
									defaultValue={selectStates.middleSelect} 
									value={selectStates.middleSelect} 
									options={STEM_DATA.slice(32, 69)} 
									onSelectChange={(e) => updateIframe(e, "2")} 
								/>
							</div>
							<div>
								<p className='text-xs text-white font-semibold'>Diploma - Grades 9-12</p>
								<StemSelect 
									defaultValue={selectStates.diplomaSelect} 
									value={selectStates.diplomaSelect} 
									options={STEM_DATA.slice(69, STEM_DATA.length)} 
									onSelectChange={(e) => updateIframe(e, "3")} 
								/>
							</div>
						</div> : null}
					</Box>
					{isDrawerOpen ? null : 
						<div 
							className='absolute flex items-center left-0 top-0 px-2 py-5 rounded-r-lg min-h-[72px]' style={{background:"#3b5998"}}
							onMouseEnter={()=>setIsDrawerOpen(true)}
						>
						<div>
							<img src={OPEN_DRAWER} alt="" className='size-5' />
						</div>
					</div>}
					{(!isMounted || isLoading) ? 
						<div style={{ position: 'fixed', top: '0', left: '0', right: '0',bottom: '0', background: '#284681', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
							<div>
								<Loader />
								<h3 style={{ color: '#FFFFFF', fontSize: '1.85rem', fontWeight: '600', fontFamily: 'Karla', }}>
									STEM Simulation {dots.map((_,i) => (<span style={{marginRight: '0.25rem'}} key={`dot-${i}`}>.</span>))}
								</h3>
							</div>
						</div> 
						: 
						<Box 
							sx = {{ 
								display: selectStates.primarySelect.id === 4 ? 'none' : 'flex', 
								position: 'absolute', 
								background: (selectStates.middleSelect.id === 41 || selectStates.diplomaSelect.id === 81 || selectStates.diplomaSelect.id === 82) ? '#FFFFFF' : '#000000', 
								height: 'min(6dvw, 6.5dvh)', 
								bottom: '10px', 
								right: '0px',
								'@media (max-width: 1080px)' : {
									height: 'min(8dvw, 5.5dvh)',
									bottom: '0px'
								}
							}} 
							style = {{ 
								aspectRatio: "70/9" 
							}}
						/>
					}
					<Overlay className={'right-auto left-6'} />
				</>
			}
			className='!hidden'
		/>
	);
}
