exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".TutorialsCards_tutorials_3WCOY div .TutorialsCards_filter-container_R_vRW .TutorialsCards_filter_1XpoG{font-family:\"Karla\";font-style:normal;font-weight:500;font-size:18px;line-height:21px;color:#000;border:1px solid #6837f1;border-radius:44px;padding:0 35px;background-color:#fff}.TutorialsCards_next-page_108-X{font-family:\"Karla\";font-style:normal;font-weight:500;font-size:18px;line-height:21px;color:#6837f1;border:4px solid #6837f1;border-radius:47px;padding:10px 15px;-webkit-transition:.3s;transition:.3s}.TutorialsCards_next-page_108-X:hover{background-color:#6837f1;color:#fff}", ""]);

// exports
exports.locals = {
	"tutorials": "TutorialsCards_tutorials_3WCOY",
	"filter-container": "TutorialsCards_filter-container_R_vRW",
	"filterContainer": "TutorialsCards_filter-container_R_vRW",
	"filter": "TutorialsCards_filter_1XpoG",
	"next-page": "TutorialsCards_next-page_108-X",
	"nextPage": "TutorialsCards_next-page_108-X"
};