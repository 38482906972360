var escape = require("../../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".WhyRobospecies_section_title_13V99{text-align:center;margin-top:117px;color:#0c2032}.WhyRobospecies_why_robospecies_section_-sDd8{background-image:url(" + escape(require("../../../assets/bg-why-robospeci.svg")) + ");background-repeat:no-repeat;background-size:100%;background-position:left;padding-top:70px}.WhyRobospecies_why_robospecies_section_-sDd8 .WhyRobospecies_robospecies_text_2r9MQ{font-family:\"Karla\";font-style:normal;font-weight:400;font-size:18px;line-height:26px;text-align:center;color:#414141}", ""]);

// exports
exports.locals = {
	"section_title": "WhyRobospecies_section_title_13V99",
	"sectionTitle": "WhyRobospecies_section_title_13V99",
	"why_robospecies_section": "WhyRobospecies_why_robospecies_section_-sDd8",
	"whyRobospeciesSection": "WhyRobospecies_why_robospecies_section_-sDd8",
	"robospecies_text": "WhyRobospecies_robospecies_text_2r9MQ",
	"robospeciesText": "WhyRobospecies_robospecies_text_2r9MQ"
};