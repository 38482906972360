import React from 'react';
import rocket from '../../../assets/roket.svg';
import Login from '../../../components/landing-page/Login/Login.jsx';
import Signup from '../../../components/landing-page/Signup/Signup.jsx';
import Navbar from '../../../shared/Navbar/Navbar';
import styles from './TutorialsHeader.css';

const TutorialsHeader = () => {
	return (
		<header className={styles.tutorialsHeader}>
			<Navbar>
				{/* Mobile banner */}
				<div className=''>
					<div className={`${styles.heroContent} hero-content flex-col justify-center mt-20`}>
						<div className={`${styles.bannerText}`}>
							<img className='w-[100px] mr-10 sm:hidden rotate-[-30deg]' src={rocket} alt='' />
							<h3 className={`${styles.bannerTitle}`}>Quests!</h3>
						</div>
						<div className='w-full'>
							<input
								type='text'
								placeholder='search for quests or fun activities '
								className={styles.searchBox}
							/>
							<button className={styles.searchButton}>Search</button>
						</div>
					</div>
				</div>
			</Navbar>
			{/* Desktop Banner */}
			<div className={`px-6 lg:px-12 ${styles.topHero} hidden lg:block`}>
				<div className={`${styles.heroContent} hero-content flex-col justify-center mt-20`}>
					<h3 className={`${styles.bannerTitle}`}>Quests!</h3>
					<div className='w-full'>
						<input
							type='text'
							placeholder='search for quests or fun activities '
							className={styles.searchBox}
						/>
						<button className={styles.searchButton}>Search</button>
					</div>
				</div>
			</div>
			{true && <Login />}
			{true && <Signup />}
		</header>
	);
};

export default TutorialsHeader;
