import React, { useState, useRef, useEffect } from 'react';
import styles from '../landing-page/Login/Login.css';
import { useDispatch, useSelector } from 'react-redux';
import { register } from '../../reducers/auth';
import ReactS3Uploader from 'react-s3-uploader';
import Image from '../../assets/robot-learning.png';
import { setProjectTitle } from '../../reducers/project-title';
import {
	clearCurrentProject,
	createProject,
	saveProject,
	updateProject,
} from '../../reducers/project';

const s3BucketUrl = 'https://tinkerbrix.s3.ap-south-1.amazonaws.com';

const SaveProjectModal = ({
	isVisible,
	hideModal,
	isNewProject,
	currentProjectTitle,
	downloadProject,
	projectId,
	isEdit,
	editCompleted,
}) => {
	const dispatch = useDispatch();
	const uploader = useRef();
	const modalRef = useRef();

	const [projectName, setProjectName] = useState('');
	const [projectDescription, setProjectDescription] = useState('');
	const [bannerS3FileKey, setBannerS3FileKey] = useState('');
	const [uploadProgress, setUploadProgress] = useState(0);
	const [bannerUploaded, setBannerUploaded] = useState(false);

	const loading = useSelector(state => state.scratchGui.project.loading);
	const coverImage = useSelector(state => state.scratchGui.project.projectData?.coverImage);
	const description = useSelector(
		state => state.scratchGui.project.projectData?.projectDescription
	);
	const backendId = useSelector(state => state.scratchGui.project.projectData?._id);

	useEffect(() => {
		if (coverImage) setBannerS3FileKey(coverImage);
		else setBannerS3FileKey('');
		if (description) setProjectDescription(description);
		else setProjectDescription('');
	}, [description, coverImage]);

	useEffect(() => {
		if (projectId == '0') {
			dispatch(clearCurrentProject());
		}
	}, [projectId]);

	useEffect(() => {
		if (isNewProject || isEdit) {
			modalRef.current.checked = isVisible;
		} else {
			if (isVisible) {
				downloadProject().then(data => {
					if (!projectId) return;
					const path = projectId.split('/');
					const fileName = path[path.length - 1];
					dispatch(saveProject({ projectFile: data.content, fileName: fileName }));
				});
				hideModal();
			}
		}
	}, [isVisible]);

	useEffect(() => {
		if (currentProjectTitle) {
			setProjectName(currentProjectTitle);
		}
	}, [currentProjectTitle]);

	const saveProjectBanner = data => {
		setBannerS3FileKey(data.fileKey);
		setBannerUploaded(true);
	};

	const createProjectTrigger = async e => {
		e.preventDefault();
		downloadProject().then(data => {
			const projectData = {
				coverImage: bannerS3FileKey,
				projectName,
				projectDescription,
				projectFile: data.content,
				fileName: data.name,
			};
			try {
				dispatch(createProject(projectData, hideModal));
			} catch (err) {
				console.log(err);
			}
		});
	};

	const upadteProjectTrigger = async e => {
		e.preventDefault();
		const projectData = {
			coverImage: bannerS3FileKey,
			projectName,
			projectDescription,
			projectId: backendId,
		};
		try {
			dispatch(updateProject(projectData, hideModal, editCompleted));
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<>
			<input ref={modalRef} type='checkbox' id='save-project-modal' className='modal-toggle' />
			<div className='modal modal-top sm:modal-middle'>
				<div className={`modal-box relative bg-white ${styles.loginModal}`}>
					<label onClick={hideModal} className={`absolute right-6 top-4 ${styles.closeBtn}`}>
						✕
					</label>
					<div className={`mt-10 px-5 ${styles.modalBody}`}>
						<h3 className={`mb-[21px] ${styles.loginTitle}`}>Save Project</h3>
						<img
							style={{ margin: 'auto', height: '13rem', objectFit: "contain" }}
							src={bannerS3FileKey !== '' ? `${s3BucketUrl}/${bannerS3FileKey}` : Image}
							alt='Project Banner'
						/>
						<div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
							<ReactS3Uploader
								signingUrl='/s3/sign'
								signingUrlMethod='GET'
								accept='*'
								s3path='projects/banners/'
								scrubFilename={filename => `project_banner`}
								onProgress={setUploadProgress}
								onFinish={saveProjectBanner}
								server={'https://app.tinkerbrix.cc'}
								autoUpload={true}
								onError={message => console.log(message)}
								uploadRequestHeaders={{
									'x-amz-acl': 'private',
								}}
								contentDisposition='auto'
								ref={uploader}
								className="flex"
							/>
							<br />
							<p style={{ color: 'black' }}>
								{bannerUploaded
									? 'Uploaded'
									: uploadProgress === 0
									? 'Upload Project Banner'
									: `${uploadProgress}%`}
							</p>
						</div>
						<form
							onSubmit={isNewProject ? createProjectTrigger : upadteProjectTrigger}
							className={`${styles.loginForm} mt-[25px]`}
						>
							<div className={`${styles.formInput}`}>
								<p>
									<label htmlFor='projectTitle'>Name</label>
								</p>
								<input
									type='text'
									id='projectTitle'
									placeholder='Project name'
									name='projectTitle'
									style={{ color: 'black' }}
									value={projectName}
									onChange={e => dispatch(setProjectTitle(e.target.value))}
								/>
							</div>

							<div className={`${styles.formInput}`}>
								<p>
									<label htmlFor='projectDescription'>Describe the project</label>
								</p>
								<input
									type='text'
									id='projectDescription'
									placeholder='Project name'
									name='projectDescription'
									style={{ color: 'black' }}
									value={projectDescription}
									onChange={e => setProjectDescription(e.target.value)}
								/>
							</div>

							<p className='text-center mb-[39px]'>
								<button disabled={loading} type='submit'>
									{loading
										? `${isNewProject ? 'Creating' : 'Saving'}...`
										: `${isNewProject ? 'Create' : 'Save'} Project`}
								</button>
							</p>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default SaveProjectModal;
