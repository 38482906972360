import projectData from './project-data';

/* eslint-disable import/no-unresolved */
import popWav from '!arraybuffer-loader!./83a9787d4cb6f3b7632b4ddfebf74367.wav';
import meowWav from '!arraybuffer-loader!./4e080acae1c3dc65eef30f07c2b0d4a5.wav';
import backdrop from '!raw-loader!./cd21514d0531fdffb22204e0ec5ed84a.svg';
import costume1 from '!raw-loader!./35C3C2489DF83730139B180042270877.svg';
import costume2 from '!raw-loader!./CBB67F48B0F73380C63C3605CA66DC7E.svg';
import costume3 from '!raw-loader!./F0543F6BD15825DBDBEA827B6DEBC75D.svg';
import costume4 from '!raw-loader!./A0A144674062E50BFB6816E29FCC3EC6.svg';
import costume5 from '!raw-loader!./08B4D7BD9DE2FD42C3208ACE16766E1A.svg';
/* eslint-enable import/no-unresolved */

const defaultProject = translator => {
	let _TextEncoder;
	if (typeof TextEncoder === 'undefined') {
		_TextEncoder = require('text-encoding').TextEncoder;
	} else {
		/* global TextEncoder */
		_TextEncoder = TextEncoder;
	}
	const encoder = new _TextEncoder();

	const projectJson = projectData(translator);
	return [
		{
			id: 0,
			assetType: 'Project',
			dataFormat: 'JSON',
			data: JSON.stringify(projectJson),
		},
		{
			id: '83a9787d4cb6f3b7632b4ddfebf74367',
			assetType: 'Sound',
			dataFormat: 'WAV',
			data: new Uint8Array(popWav),
		},
		{
			id: '4e080acae1c3dc65eef30f07c2b0d4a5',
			assetType: 'Sound',
			dataFormat: 'WAV',
			data: new Uint8Array(meowWav),
		},
		{
			id: 'cd21514d0531fdffb22204e0ec5ed84a',
			assetType: 'ImageVector',
			dataFormat: 'SVG',
			data: encoder.encode(backdrop),
		},
		{
			id: '35C3C2489DF83730139B180042270877',
			assetType: 'ImageVector',
			dataFormat: 'SVG',
			data: encoder.encode(costume1),
		},
		{
			id: 'CBB67F48B0F73380C63C3605CA66DC7E',
			assetType: 'ImageVector',
			dataFormat: 'SVG',
			data: encoder.encode(costume2),
		},
		{
			id: 'F0543F6BD15825DBDBEA827B6DEBC75D',
			assetType: 'ImageVector',
			dataFormat: 'SVG',
			data: encoder.encode(costume3),
		},
		{
			id: 'A0A144674062E50BFB6816E29FCC3EC6',
			assetType: 'ImageVector',
			dataFormat: 'SVG',
			data: encoder.encode(costume4),
		},
		{
			id: '08B4D7BD9DE2FD42C3208ACE16766E1A',
			assetType: 'ImageVector',
			dataFormat: 'SVG',
			data: encoder.encode(costume5),
		},
	];
};

export default defaultProject;
