exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Testimonials_testimonials-title_GrsmK{font-family:\"Kufam\";font-style:normal;font-weight:700;font-size:30px;line-height:26px;text-align:center;color:#414141;margin-bottom:54px}.Testimonials_name_11I2s{font-weight:400}.Testimonials_title_1ovqR{font-weight:500}.Testimonials_comment_3vlIu{font-family:\"Kufam\";font-style:normal;font-weight:400;font-size:18px;line-height:26px;color:#414141}", ""]);

// exports
exports.locals = {
	"testimonials-title": "Testimonials_testimonials-title_GrsmK",
	"testimonialsTitle": "Testimonials_testimonials-title_GrsmK",
	"name": "Testimonials_name_11I2s",
	"title": "Testimonials_title_1ovqR",
	"comment": "Testimonials_comment_3vlIu"
};