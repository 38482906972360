import axios from '../api/axios';
import { showAlertWithTimeout } from './alerts';
import { setProjectId } from './project-state';
import { setProjectTitle } from './project-title';

export const PROJECT_LOADING = 'scratch-gui/project/PROJECT_LOADING';
export const PROJECT_FAILED = 'scratch-gui/project/PROJECT_FAILED';

export const PROJECT_CREATED = 'scratch-gui/project/PROJECT_CREATED';
export const PROJECT_UPDATED = 'scratch-gui/project/PROJECT_UPDATED';
export const MY_PROJECTS_LOADED = 'scratch-gui/project/MY_PROJECTS_LOADED';
export const PROJECT_LOADED = 'scratch-gui/project/PROJECT_LOADED';
export const PROJECT_UPLOAD_UPDATED = 'scratch-gui/project/PROJECT_UPLOAD_UPDATED';
export const CLEAR_PROJECT_DATA = 'scratch-gui/project/CLEAR_PROJECT_DATA';

const projectInitialState = {
	loading: null,
	projectData: {},
	myProjects: [],
	message: '',
	uploadPercentage: 0,
};

const projectReducer = function (state, action) {
	if (typeof state === 'undefined') state = projectInitialState;
	const { type, payload } = action;
	switch (type) {
		case PROJECT_LOADING:
			return {
				...state,
				loading: true,
			};
		case PROJECT_CREATED:
		case PROJECT_UPDATED:
			return {
				...state,
				projectData: payload.project,
				message: payload.message,
				loading: false,
			};
		case CLEAR_PROJECT_DATA:
			return {
				...state,
				projectData: {},
			};
		case MY_PROJECTS_LOADED:
			return {
				...state,
				myProjects: payload,
				loading: false,
			};
		case PROJECT_FAILED:
			return {
				...state,
				loading: false,
			};
		case PROJECT_LOADED:
			return {
				...state,
				projectData: payload,
				loading: false,
			};
		case PROJECT_UPLOAD_UPDATED:
			return {
				...state,
				uploadPercentage: payload,
			};
		default:
			return state;
	}
};

const uploadProjectFile = async (file, fileName, isNewFile, dispatch) => {
	dispatch({
		type: PROJECT_UPLOAD_UPDATED,
		payload: 0,
	});
	let fileType = file.type;
	let response = await axios.get(
		`https://app.tinkerbrix.cc/s3/${isNewFile ? 'sign' : 'signOverwrite'}`,
		{
			params: { objectName: fileName, path: `projects/files/` },
		}
	);
	var signedRequest = response.data.signedUrl;
	var options = {
		headers: {
			'Content-Type': fileType,
			'Cache-Control': 'no-cache',
		},
		onUploadProgress: function (progressEvent) {
			var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
			dispatch({
				type: PROJECT_UPLOAD_UPDATED,
				payload: percentCompleted,
			});
		},
	};
	await axios.put(signedRequest, file, options);
	return response.data.fileKey;
};

const createProject =
	({ coverImage, projectName, projectDescription, projectFile, fileName }, closeModal) =>
	async dispatch => {
		try {
			dispatch({
				type: PROJECT_LOADING,
			});
			const projectURL = await uploadProjectFile(projectFile, fileName, true, dispatch);
			if (!coverImage || coverImage == '') {
				coverImage = 'projects/banners/default_banner.png';
			}
			const res = await axios.post(
				'/api/project/createProject',
				{ coverImage, projectName, projectDescription, projectURL },
				{
					headers: {
						'Content-Type': 'application/json',
					},
				}
			);
			closeModal();
			showAlertWithTimeout(dispatch, 'createSuccess');
			dispatch({
				type: PROJECT_CREATED,
				payload: res.data,
			});
		} catch (err) {
			console.log(err);
			if (!err.response.data.errors) {
				//todo add alerts and all
			} else {
			}
			dispatch({
				type: PROJECT_FAILED,
			});
		}
	};

const updateProject =
	({ coverImage, projectName, projectDescription, projectId }, hideModal, editCompleted) =>
	async dispatch => {
		try {
			showAlertWithTimeout(dispatch, 'saving');
			dispatch({
				type: PROJECT_LOADING,
			});
			if (!coverImage || coverImage == '') {
				coverImage = 'projects/banners/default_banner.png';
			}
			const res = await axios.post(
				`/api/project/updateProject/${projectId}`,
				{ coverImage, projectName, projectDescription },
				{
					headers: {
						'Content-Type': 'application/json',
					},
				}
			);
			hideModal();
			editCompleted();
			getMyProjects();
			showAlertWithTimeout(dispatch, 'saveSuccess');
			dispatch({
				type: PROJECT_UPDATED,
				payload: res.data,
			});
		} catch (err) {
			console.log(err);
			if (!err.response.data.errors) {
				//todo add alerts and all
			} else {
			}
			dispatch({
				type: PROJECT_FAILED,
			});
		}
	};

const getMyProjects = () => async dispatch => {
	try {
		dispatch({
			type: PROJECT_LOADING,
		});
		const res = await axios.get('/api/project/myProjects');
		dispatch({
			type: MY_PROJECTS_LOADED,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
		if (!err.response.data.errors) {
			//todo add alerts and all
		} else {
		}
		dispatch({
			type: PROJECT_FAILED,
		});
	}
};

const loadProject = (projectData, hideModal) => async dispatch => {
	dispatch(setProjectId(projectData.projectURL, projectData.projectDescription));
	dispatch(setProjectTitle(projectData.projectName));
	dispatch({
		type: PROJECT_LOADED,
		payload: projectData,
	});
	hideModal();
};

const saveProject =
	({ projectFile, fileName }) =>
	async dispatch => {
		showAlertWithTimeout(dispatch, 'saving');
		try {
			await uploadProjectFile(projectFile, fileName, false, dispatch);
			showAlertWithTimeout(dispatch, 'saveSuccess');
		} catch (err) {
			showAlertWithTimeout(dispatch, 'savingError');
		}
	};

const clearCurrentProject = () => {
	return {
		type: CLEAR_PROJECT_DATA,
	};
};

export {
	projectReducer as default,
	projectInitialState,
	createProject,
	getMyProjects,
	loadProject,
	saveProject,
	updateProject,
	clearCurrentProject,
};
