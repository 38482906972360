exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Avatar_profile_1RGZt{width:40px;height:40px;background-color:#6837f1;border-radius:50%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;-ms-flex-align:center;align-items:center;z-index:999}.Avatar_profile_1RGZt img{z-index:119;border-radius:50%}", ""]);

// exports
exports.locals = {
	"profile": "Avatar_profile_1RGZt"
};