import React from 'react';
import LearnHeader from '../components/learn-page/LearnHeader/LearnHeader';
import Subjects from '../components/learn-page/Subjects/Subjects';
import Footer from '../shared/Footer/Footer';

const Learn = () => {
	return (
		<>
			<LearnHeader />
			<Subjects />
			<Footer />
		</>
	);
};

export default Learn;
