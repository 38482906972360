import Overlay from '../../../shared/Overlay/Overlay.jsx'
import PhysicsNavbar from './physics-navbar.jsx'
import AXIS from './images/axis.svg'
import CUBE from './images/cube.svg'
import SPHERE from './images/sphere.svg'
import CYLINDER from './images/cylinder.svg'
import BLOCK from './images/block.svg'
import { useRef } from 'react'
import React, { useEffect, useState, Fragment } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { Physics, useBox } from '@react-three/cannon'
import { OrbitControls } from '@react-three/drei'
import { Csm } from './csm.jsx'

const numInstance = 30
const gravityForce = 10
const speed = 16
const positionY = 2

function Cube({ args = [1, 1, 1], ...props }) {
  const [ref, api] = useBox(() => ({
    args,
    mass: 10,
    // type: 'Kinematic',
    onCollide: (e) => {
      handleClick() // Appelez une fonction de gestion de collision
    },
    ...props
  }))
  const [isCollide, setIsCollide] = useState(false)
  const [isLevitate, setIsLevitate] = useState(true)
  const [isTimeOut, setIsTimeOut] = useState(true)

  const handleClick = () => {
    setIsLevitate(false)
  }

  setTimeout(() => {
    if (isTimeOut == true) {
      setIsTimeOut(false)
      setIsLevitate(false)
    }
  }, 2000)

  // Function to reset the cube's position and physics properties
  const resetPosition = () => {
    api.mass.set(1)
    api.position.set(-4, positionY, 2)
    api.velocity.set(0, 0, 0)
    api.angularVelocity.set(0, 0, 0)
    setIsLevitate(true)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (isLevitate === false) {
        // console.log('collision détectée')
        resetPosition()
      }
    }, 4000) // L'intervalle est défini en millisecondes (dans cet exemple, 1 seconde)

    // Assurez-vous de nettoyer l'intervalle lorsque le composant est démonté
    return () => clearInterval(interval)
  }, [isLevitate])

  // // Apply forces and impulses if no collision
  // useFrame(() => {
  //   if (isLevitate == true) {
  //     api.applyForce([0, gravityForce, 0], [0, 0, 0])
  //     // api.position.y = 4
  //     api.applyImpulse([0.05, 0, 0], [0, 0, 0])
  //   }
  // }, [api, isLevitate])
  // const velocity = useRef([0, 4, 0])
  // useEffect(() => {
  //   const unsubscribe = api.velocity.subscribe((v) => (velocity.current = v))
  //   return unsubscribe
  // }, [])

  useFrame(() => {
    if (isLevitate == true) {
      api.velocity.set(speed, 0, 0) // Déplace le cube en X sans changer la position en Y
    }
  }, [api, isLevitate])

  return (
    <mesh castShadow ref={ref} onPointerDown={handleClick}>
      {/* <mesh castShadow ref={ref} onPointerDown={handleClick} onCollide={handleClick}> */}
      <boxGeometry args={args} />
      <meshStandardMaterial color="orange" />
    </mesh>
  )
}

function InstancedBoxes({count}) {
  // const [isCollide, setIsCollide] = useState(false)
  const [instanceProperties, setInstanceProperties] = useState(Array(count).fill({ isCollide: false }))

  const [ref, { at }] = useBox(
    (i) => ({
      args: [1, 1, 1],
      type: 'Dynamic',
      position: [i * 2, positionY, 2],
      mass: 1,
      onCollide: () => {
        // Utilisez une fonction de rappel pour mettre à jour isCollide
        setInstanceProperties((prevInstanceProperties) => {
          // Créez une copie de l'objet instanceProperties
          const updatedInstanceProperties = [...prevInstanceProperties]
          // Mettez à jour la propriété isCollide pour l'instance actuelle
          updatedInstanceProperties[i] = { isCollide: true }
          return updatedInstanceProperties
        })
      }
    }),
    useRef()
  )

  useFrame(() => {
    for (let i = 0; i < count; i++) {
      //console.log(instanceProperties[i].isCollide)
      if (instanceProperties[i].isCollide == false) {
        // Mettez à jour la propriété de l'instance actuelle
        // instanceProperties[i].isCollide = true
        let randomDirection = Math.random()
        let impulse = randomDirection < 0.5 ? -0.5 : 0.5

        at(i).velocity.set(0, 0, impulse) // Déplace le cube en X sans changer la position en Y

        // at(i).applyForce([0, gravityForce, 0], [0, 0, 0])
        // at(i).applyImpulse([0, 0, impulse], [0, 0, 0])
      }
    }
  }, [at, instanceProperties])

  // useEffect(() => {
  //   for (let i = 0; i < 40; i++) {
  //     if (isCollide) {
  //       at(i).mass.set(1)
  //       at(i).position.set(i * 2, 4, 2)
  //       console.log('ok collide', isCollide)
  //     }
  //     // setIsCollide(true)
  //   }
  // }, [at, isCollide])

  return (
    <instancedMesh
      castShadow
      ref={ref}
      args={[undefined, undefined, count]}
      onPointerDown={(e) => {
        e.stopPropagation()
        at(e.instanceId).mass.set(1)
      }}>
      <boxGeometry args={[1, 1, 1]} />
      <meshStandardMaterial color="red" />
    </instancedMesh>
  )
}

function Plane({ args = [1000, 1000, 2], ...props }) {
  const [ref] = useBox(() => ({ args: args, rotation: [-Math.PI / 2, 0, 0], ...props }))
  return (
    <mesh receiveShadow ref={ref}>
      <boxGeometry args={args} />
      <meshStandardMaterial color="#121212" />
    </mesh>
  )
}

const MENU_ITEMS = [
    {
        name: 'Plane',
        img: AXIS,
        imgSize: 'size-10',
        id: 'PLANE'
    },
    {
        name: 'Cube',
        img: CUBE,
        className: 'gap-[6px]',
        imgSize: 'size-8',
        id: 'CUBE'
    },
    {
        name: 'Sphere',
        img: SPHERE,
        className: 'gap-[6px]',
        imgSize: 'size-7',
        id: 'SPHERE'
    },
    {
        name: 'Cylinder',
        img: CYLINDER,
        className: 'gap-[6px]',
        imgSize: 'size-7',
        id: 'CYLINDER'
    },
    {
        name: 'Block',
        img: BLOCK,
        className: 'gap-[6px]',
        imgSize: 'size-7',
        id: 'BLOCK'
    },
]

const EMPTY_ITEMS = {
  PLANE: {
    x: 0,
    y: 0,
    z: 0
  },
  CUBE: {x: 0, y: 0, z:0},
  SPHERE: {x: 0, y: 0,z:0},
  CYLINDER: {x: 0, y: 0,z:0},
  BLOCK: {x: 0, y: 0,z:0},
}


const PhysicsEngine = () => {
  const isKeyValueEmpty = (obj) => {
    return Object.values(obj).every(item => item === 0);
  };

  const [openSubMenu, setOpenSubMenu] = useState(null);

  const [view, setView] = useState({
    CUBE: {
      x: 0, 
      y: 0,
      z: 0,
      velocity: 0,
      direction: 0,
      count: 0
    },
    SPHERE: {
      x: 0, 
      y: 0,
      z: 0,
      velocity: 0,
      direction: 0,
      count: 0
    },
    PLANE: {
      x: 1000,
      y: 1000,
      z: 2
    },
    CYLINDER: {
      x:0,
      y:0,
      z:0
    },
    BLOCK: {
      x:0,
      y:0,
      z:0
    }
  });

  const menuActions = (key, values) => {
    if(isKeyValueEmpty(view[key])){
      setView({
        ...view,
        [key]: values
      });
    } else {
      setView({
        ...view,
        [key]: EMPTY_ITEMS[key]
      });
    }
  };

  const [formValue, setFormValue] = useState({
    x: 0,
    y: 0,
    z: 0,
    velocity: 0,
    direction: 0,
    count: 0
  })

  const handleClick = (i) => {
    setOpenSubMenu(i);
  }

  const closeSubMenu =()=>{
    setOpenSubMenu(null)
    
  }

  const handleChange = (e, id) => {
    e.persist();
    setView(prevState => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        [e.target.name]: e.target.value
      }
    }))
    // setFormValue(prevState => ({...prevState, [e.target.name]: Number(e.target.value)}));
  }

  const handleSubmit = (e, id) => {
    e.preventDefault();
    setFormValue({ x: 0, y: 0, z: 0 })
    setOpenSubMenu(true);
    
    if(id==="PLANE"){
      menuActions(id, {...formValue, z: 2})
    } else {
      menuActions(id, formValue)
    }
  }

  return (
    <div className={`w-full bg-[#121212] min-h-[100dvh]`}>
        <Overlay />
        <PhysicsNavbar />
        <div className='flex w-full h-[calc(100dvh-84px)] relative'>
            <div 
                className='z-10 px-3 py-4 bg-[#3b5998] h-[90%] flex flex-col gap-3 border-t border-[#3b599820] rounded-lg my-auto text-white font-semibold text-sm absolute left-20 top-1/2 -translate-x-1/2 -translate-y-1/2' 
                style={{
                    fontFamily: "Poppins, 'sans serif'"
                }}
            >
                {MENU_ITEMS.map((menu, i) => (
                  <div key={menu.name} className='relative'>
                    <button 
                      className={`py-3 px-4 w-full rounded-lg hover:bg-[#00000025] flex flex-col justify-center items-center ${(!isKeyValueEmpty(view[menu.id]) || openSubMenu === i) && 'bg-[#00000025]'} ${menu?.className}`}
                      onClick={() => handleClick(i)}
                    >
                      <img src={menu.img} alt="" className={`${menu?.imgSize}`} />
                      {menu.name}
                    </button>
                    {openSubMenu === i &&
                      <div className='absolute bg-[#3b5998] rounded-lg p-5 left-[120%] top-0'>
                        <form className='flex flex-col items-start gap-1' onSubmit={(e) => handleSubmit(e, menu.id)}>
                          {menu.id}
                          {Object.keys(view[menu.id]).map((key) => (
                            <Fragment key={key}>
                              <label htmlFor={key} className='capitalize text-xs'>{key}:</label>
                              <input type="number" name={key} className='rounded-sm mb-1 text-black px-2 py-1 text-sm' value={view[menu.id][key]} onChange={(e) => handleChange(e, menu.id)} />
                            </Fragment>
                          ))}
                          <div className='flex justify-between w-full'>
                            <button className='pointer mt-1 bg-[#AA0000] px-3 py-[6px] rounded-lg' onClick={()=>closeSubMenu()}>Close</button>
                            <input type="submit" className='mt-1 bg-[#17B169] px-3 py-[6px] rounded-lg' />
                          </div>
                        </form>
                      </div>
                    }  
                  </div>
                ))}
            </div>
            <div className='w-full h-full'>
              <Canvas dpr={[1, 2]} shadows camera={{ position: [-5, 5, 5], fov: 50 }}>
                <Csm lightDirection={[1, 1, 1]}>
                  <ambientLight intensity={[0.5]} />
                  <Physics>
                  {/* <Debug color="black" scale={1.1}> */}
                  {!isKeyValueEmpty(view.PLANE) && <Plane args={[view.PLANE.x, view.PLANE.y, view.PLANE.z]} />}
                  {!isKeyValueEmpty(view.CUBE) && <Cube position={[-4, positionY, 2]} />}
                  {!isKeyValueEmpty(view.SPHERE) && <InstancedBoxes count={numInstance} />} 
                  {/* </Debug> */}
                  </Physics>
                </Csm>
                <OrbitControls />
              </Canvas>
            </div>
        </div>
    </div>
  )
}

export default PhysicsEngine