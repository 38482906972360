import React from 'react';
import IframePage from './iframe-page.jsx';
import ELECTRONICS from '../assets/electronics.svg'
import Overlay from '../shared/Overlay/Overlay.jsx';

export default function Circuits() {
	return (
		<IframePage
			Header={
				<>
					<img src={ELECTRONICS} alt="" style={{width: '56px', height: '56px'}} />
					<h2 className='text-white ml-2'>ELECTRONICS</h2>
				</>
			}
			iframe={
				<iframe
					src='https://www.falstad.com/circuit/circuitjs.html?startCircuit=fullrect.txt'
					width='100%'
					height='100%'
					frameBorder='0'
					margiWwidth='0'
					marginHeight='0'
					allowFullScreen
					style={{
						position: 'absolute',
						top: '76px',
						left: 0,
						right: 0,
						bottom: 0,
						border: 'none',
					}}
				/>
			}
			addon={<Overlay />}
		/>
	);
}
