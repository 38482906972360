var escape = require("../../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ProfileHeader_dashboard-header_2e2US{position:relative;min-height:30vh;background:url(" + escape(require("../../../assets/wave-dashboard.svg")) + ");background-size:101%;background-repeat:no-repeat;background-position:bottom -50px left 0px;background-color:#3b5998;position:relative;z-index:1}.ProfileHeader_profile_1lM7w{width:112px;height:112px;background-color:#6837f1;border-radius:50%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;-ms-flex-align:center;align-items:center;z-index:999}.ProfileHeader_profile_1lM7w img{width:70px;z-index:119}.ProfileHeader_user-info_2wkwk h3{font-family:\"Karla\";font-style:normal;font-weight:500;font-size:24px;line-height:28px;color:#0c2032}.ProfileHeader_user-info_2wkwk p{font-family:\"Karla\";font-style:normal;font-weight:300;font-size:16px;line-height:19px;color:#414141}@media only screen and (max-width: 576px){.ProfileHeader_dashboard-header_2e2US{background-position:center}}@media only screen and (max-width: 466px){.ProfileHeader_dashboard-header_2e2US{background-position:center}}", ""]);

// exports
exports.locals = {
	"dashboard-header": "ProfileHeader_dashboard-header_2e2US",
	"dashboardHeader": "ProfileHeader_dashboard-header_2e2US",
	"profile": "ProfileHeader_profile_1lM7w",
	"user-info": "ProfileHeader_user-info_2wkwk",
	"userInfo": "ProfileHeader_user-info_2wkwk"
};