exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".save-status_save-now_1Blzi {\r\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\r\n    cursor: pointer;\r\n}\r\n", ""]);

// exports
exports.locals = {
	"save-now": "save-status_save-now_1Blzi",
	"saveNow": "save-status_save-now_1Blzi"
};