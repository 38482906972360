import React, { useState } from 'react';
import { useEffect } from 'react';
import { Parallax } from 'react-scroll-parallax';
import planet1 from '../../../assets/Planet-1.svg';
import planet2 from '../../../assets/Planet-2.svg';
import robot1 from '../../../assets/robot-1.svg';
import robot2 from '../../../assets/robot-2.svg';
import robot3 from '../../../assets/robot-3.svg';
import CustomButton from '../../../shared/CustomButton/CustomButton';
import Navbar from '../../../shared/Navbar/Navbar';
import Login from '../Login/Login.jsx';
import Signup from '../Signup/Signup.jsx';
import styles from './Header.css';

const Header = () => {
	const [isActive, setIsActive] = useState(false);

	const hideRobot = () => {
		if (window.scrollY >= 800) {
			setIsActive(true);
		} else {
			setIsActive(false);
		}
	};
	useEffect(() => {
		window.addEventListener('scroll', hideRobot);
	}, []);

	return (
		<header className={styles.header}>
			<Navbar>
				<div className={`${styles.heroContent} flex-col lg:flex-row`}>
					<div className={`${styles.heroTextContainer} mt-16 lg:mt-0`}>
						<h1 className={`text-3xl font-bold text-white ${styles.heroTitle} text-center`}>
							Learn
						</h1>
						<h1 className={`text-5xl font-bold text-white ${styles.heroTitle} text-center`}>
							Robotics, Coding & AI
						</h1>
						<p className='text-white text-center text-xl'>For Ages 6 and Above</p>
						<div className='text-center mt-5'>
							<CustomButton>Start Learning!</CustomButton>
						</div>
					</div>
					<div className={`lg:w-[50%] flex justify-center ${styles.botsContainer}`}>
						<Parallax scale={[2, 0.2]} speed={-10}>
							<img className='w-[600px]' src={planet1} alt='' />
						</Parallax>
						{/*//! Desktop Version */}
						<div className='flex mt-20'>
							<Parallax speed={10} translateX={[10]}>
								<img className={styles.robotOne} src={robot1} alt='' />
							</Parallax>
							<Parallax speed={10} translateX={[100]}>
								<img className={`${styles.robotTwo}`} src={robot2} alt='' />
							</Parallax>
							<Parallax speed={10} translateX={[10]}>
								<img className={styles.robotThree} src={robot3} alt='' />
							</Parallax>
						</div>
						<Parallax scale={[2.5, 0.2]}>
							<img className='w-[700px]' src={planet2} alt='' />
						</Parallax>
					</div>
				</div>
			</Navbar>
			{/* TOP Hero */}
			<div className={`px-6 lg:px-12 ${styles.topHero} mt-16 hidden lg:block  pt-20`}>
				<div className={`${styles.heroContent} hero-content flex-col lg:flex-row`}>
					<div className={`lg:w-[50%] ${styles.heroTextContainer}`}>
						<h1 className={`text-3xl font-bold ${styles.heroTitle}`}>Learn</h1>
						<h1 className={`text-5xl font-bold ${styles.heroTitle}`}>Robotics, Coding & AI</h1>
						<p className='text-white text-xl'>For Ages 6 and Above</p>
						<div className='mt-[41px]'>
							<CustomButton>Start Learning!</CustomButton>
						</div>
					</div>
					<div className={`lg:w-[50%] flex justify-center ${styles.botsContainer}`}>
						<Parallax scale={[3, 0.2]} speed={-10}>
							<img className='w-[300px]' src={planet1} alt='' />
						</Parallax>

						<Parallax speed={10} translateX={[0, -100]}>
							<img className={`${styles.botOne} scale-125`} src={robot1} alt='' />
						</Parallax>
						<Parallax speed={-85}>
							<img
								className={`${styles.botTwo} ${isActive ? 'hidden' : 'block'}`}
								src={robot2}
								alt=''
							/>
						</Parallax>
						<Parallax speed={10}>
							<img className={styles.botThree} src={robot3} alt='' />
						</Parallax>
						<Parallax scale={[5, 0.2]}>
							<img className='w-[300px]' src={planet2} alt='' />
						</Parallax>
					</div>
				</div>
			</div>
			{true && <Login />}
			{true && <Signup />}
		</header>
	);
};

export default Header;
