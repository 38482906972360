import React from 'react';
import parentImg from '../../../assets/Parent-educator.svg';
import styles from './Parents.css';

const Parents = () => {
	return (
		<section className={`${styles.parents} py-20`}>
			<div className={`px-6 lg:px-12 ${styles.top_hero}`}>
				<div className={`flex flex-col lg:flex-row-reverse items-center`}>
					<h3 className={`${styles.parentTitle} block lg:hidden`}>
						Educators, Using TinkerBrix to Teach
					</h3>
					<div className={`lg:w-[50%] flex justify-center overflow-hidden ${styles.botsContainer}`}>
						<img className={`${styles.bot} ${styles.bot1}`} src={parentImg} alt='' />
					</div>
					<div className='lg:w-[50%] text-center lg:text-left'>
						<h3 className={`${styles.parentTitle} hidden lg:block`}>
							Educators, Using TinkerBrix to Teach{' '}
						</h3>
						<p className={styles.parentsText}>
							Educators gets access to “FREE Learning Resources” to teach students in a step wise
							manner. Educator use TinkerBrix to teach students Scratch Projects, Block Coding,
							Python, Electronics Simulations, Arduino, Game Development, STEM concepts. Educators
							prefer TinkerBrix for easy student login to access and save all the projects at one
							place and also can publish for the community. TinkerBrix is a must have tool for every
							educator.
						</p>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Parents;
