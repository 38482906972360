import React from 'react';
import facebook from '../../assets/facebook-logo.svg';
import instagram from '../../assets/instagram-logo.svg';
import linkedin from '../../assets/linkedin-logo.svg';
import logo from '../../assets/logo.svg';

const Footer = () => {
	return (
		<footer className='md:footer px:5 md:px-10 py-6 bg-[#284681] text-white flex justify-center md:justify-between'>
			<div className='text-[#2F2141] px-3 py-3'>
				<img className='w-[200px]' src={logo} alt='' />
			</div>
			<div className='flex items-center gap-5'>
				<a href='https://www.facebook.com/robospecies'>
					<img className='size-[42px]' src={facebook} alt='' />
				</a>
				<a href='https://www.linkedin.com/company/robospecies/'>
					<img className='size-[33px] mt-[7px]' src={linkedin} alt='' />
				</a>
				<a href='https://www.instagram.com/robospecies/'>
					<img className='w-[31px] mt-[6px]' src={instagram} alt='' />
				</a>
			</div>
		</footer>
	);
};

export default Footer;
