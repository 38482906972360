import React from 'react';
import TutorialsCards from '../components/tutorials-page/TutorialsCards/TutorialsCards';
import TutorialsHeader from '../components/tutorials-page/TutorialsHeader/TutorialsHeader';
import Footer from '../shared/Footer/Footer';

const Tutorials = () => {
  return (
    <>
      <TutorialsHeader />
      <TutorialsCards />
      <Footer />
    </>
  );
};

export default Tutorials;
