import React from 'react';
import leftArrow from '../../../assets/left-arrow.svg';
import rightArrow from '../../../assets/right-arrow.svg';
import parentImg from '../../../assets/testimonial-img.svg';
import styles from './Testimonials.css';

const testimonials = [
	{
		id: 1,
		title: 'Sneha Gupta',
		parentOf: 'Jyoti Gupta',
		Grade: '8th',
		comment:
			'As a parent I can now understand what my child is doing as TinkerBrix cks helps to showcase his creativity.',
		img: '../../../assets/educator.svg',
	},
	{
		id: 2,
		title: 'Sneha Gupta',
		parentOf: 'Jyoti Gupta',
		Grade: '8th',
		comment:
			'As a parent I can now understand what my child is doing as TinkerBrix cks helps to showcase his creativity.',
		img: '../../../assets/educator.svg',
	},
	{
		id: 3,
		title: 'Sneha Gupta',
		parentOf: 'Jyoti Gupta',
		Grade: '8th',
		comment:
			'As a parent I can now understand what my child is doing as TinkerBrix cks helps to showcase his creativity.',
		img: '../../../assets/educator.svg',
	},
];

const Testimonials = () => {
	return (
		<section className='mt-20 bg-[#FAFFEB] pb-28 pt-14'>
			<h3 className={styles.testimonialsTitle}>TESTIMONIALS</h3>
			<div className='carousel w-full'>
				<div id='slide1' className='carousel-item relative w-full'>
					<div className='py-10 w-full'>
						<div className='flex gap-20 justify-center items-center'>
							<div className='w-[30%]'>
								<img className='w-[460px]' src={parentImg} alt='' />
							</div>
							<div className='w-[30%]'>
								<h3 className='font-semibold text-black text-xl'>Sneha Gupta</h3>
								<div className='flex gap-4 mt-4'>
									<p className={`${styles.title} text-black`}>Parent of </p>
									<p className={`${styles.name} text-black`}>: {' Jyoti Gupta'}</p>
								</div>
								<div className='flex gap-10 mb-4'>
									<p className={`${styles.title} text-black`}>Grade </p>
									<p className={`${styles.name} text-black`}> : {' 8th'}</p>
								</div>
								<p className={styles.comment}>
									As a parent I can now understand what my child is doing as TinkerBrix cks helps to
									showcase his creativity.
								</p>
							</div>
						</div>
					</div>
					<div className='absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2'>
						<a href='#slide4'>
							<img src={leftArrow} alt='' />
						</a>
						<a href='#slide2'>
							<img src={rightArrow} alt='' />
						</a>
					</div>
				</div>
				{/* 
        {testimonials.map(testimonial => (
          <div id={`slide${1++}`} key={testimonial.id} className='carousel-item relative w-full'>
            <div className='py-10 w-full'>
            <div className='text-center text-5xl'>Helllo 3</div>
          </div>
          <div className='absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2'>
            <a href='#slide4'>
              <img src={leftArrow} alt='' />
            </a>
            <a href='#slide2'>
              <img src={rightArrow} alt='' />
            </a>
          </div>
          </div>
        ))} */}

				<div id='slide2' className='carousel-item relative w-full'>
					<div className='py-10 w-full'>
						<div className='flex gap-20 justify-center items-center'>
							<div className='w-[30%]'>
								<img className='w-[460px]' src={parentImg} alt='' />
							</div>
							<div className='w-[30%]'>
								<h3 className='font-semibold text-black text-xl'>Prashant Chowdhuri</h3>
								<div className='flex gap-4 mt-4'>
									<p className={`${styles.title} text-black`}>Parent of </p>
									<p className={`${styles.name} text-black`}>: {' Jyoti Gupta'}</p>
								</div>
								<div className='flex gap-10 mb-4'>
									<p className={`${styles.title} text-black`}>Grade </p>
									<p className={`${styles.name} text-black`}> : {' 8th'}</p>
								</div>
								<p className={styles.comment}>
									As a parent I can now understand what my child is doing as TinkerBrix cks helps to
									showcase his creativity.
								</p>
							</div>
						</div>
					</div>
					<div className='absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2'>
						<a href='#slide1'>
							<img src={leftArrow} alt='' />
						</a>
						<a href='#slide3'>
							<img src={rightArrow} alt='' />
						</a>
					</div>
				</div>
				<div id='slide3' className='carousel-item relative w-full'>
					<div className='py-10 w-full'>
						<div className='flex gap-20 justify-center items-center'>
							<div className='w-[30%]'>
								<img className='w-[460px]' src={parentImg} alt='' />
							</div>
							<div className='w-[30%]'>
								<h3 className='font-semibold text-black text-xl'>Shrellekkha Chottopaddah</h3>
								<div className='flex gap-4 mt-4'>
									<p className={`${styles.title} text-black`}>Parent of </p>
									<p className={`${styles.name} text-black`}>: {' Jyoti Gupta'}</p>
								</div>
								<div className='flex gap-10 mb-4'>
									<p className={`${styles.title} text-black`}>Grade </p>
									<p className={`${styles.name} text-black`}> : {' 8th'}</p>
								</div>
								<p className={styles.comment}>
									As a parent I can now understand what my child is doing as TinkerBrix cks helps to
									showcase his creativity.
								</p>
							</div>
						</div>
					</div>
					<div className='absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2'>
						<a href='#slide2'>
							<img src={leftArrow} alt='' />
						</a>
						<a href='#slide4'>
							<img src={rightArrow} alt='' />
						</a>
					</div>
				</div>
				<div id='slide4' className='carousel-item relative w-full'>
					<div className='py-10 w-full'>
						<div className='flex gap-20 justify-center items-center'>
							<div className='w-[30%]'>
								<img className='w-[460px]' src={parentImg} alt='' />
							</div>
							<div className='w-[30%]'>
								<h3 className='font-semibold text-black text-xl'>Ashis Sen</h3>
								<div className='flex gap-4 mt-4'>
									<p className={`${styles.title} text-black`}>Parent of </p>
									<p className={`${styles.name} text-black`}>: {' Jyoti Gupta'}</p>
								</div>
								<div className='flex gap-10 mb-4'>
									<p className={`${styles.title} text-black`}>Grade </p>
									<p className={`${styles.name} text-black`}> : {' 8th'}</p>
								</div>
								<p className={styles.comment}>
									As a parent I can now understand what my child is doing as TinkerBrix cks helps to
									showcase his creativity.
								</p>
							</div>
						</div>
					</div>
					<div className='absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2'>
						<a href='#slide3'>
							<img src={leftArrow} alt='' />
						</a>
						<a href='#slide1'>
							<img src={rightArrow} alt='' />
						</a>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Testimonials;
