export const languageOptions = [
  {
    id: 71,
    name: "Python (3.8.1)",
    label: "Python (3.8.1)",
    value: "python",
  },
  {
    id: 76,
    name: "C++ (Clang 7.0.1)",
    label: "C++ (Clang 7.0.1)",
    value: "cpp",
  },
  // {
  //   id: 63,
  //   name: "JavaScript (Node.js 12.14.0)",
  //   label: "JavaScript (Node.js 12.14.0)",
  //   value: "javascript",
  // },
  // {
  //   id: 62,
  //   name: "Java (OpenJDK 13.0.1)",
  //   label: "Java (OpenJDK 13.0.1)",
  //   value: "java",
  // },
  // {
  //   id: 68,
  //   name: "PHP (7.4.1)",
  //   label: "PHP (7.4.1)",
  //   value: "php",
  // },
];
