import React, { useEffect, useState } from "react";
import CodeEditorWindow from "./CodeEditorWindow";
import axios from "axios";
import { classnames, convertHTML } from "../../util/general";
import { languageOptions } from "../../constants/languageOptions";
import { ToastContainer, toast } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";

injectStyle();

import styles from './CodeLanding.css'

import useKeyPress from "../../hooks/useKeyPress";
import OutputWindow from "./OutputWindow";
import CustomInput from "./CustomInput";
import OutputDetails from "./OutputDetails";
import Overlay from "../../shared/Overlay/Overlay.jsx";
import ThemeDropdown from "./ThemeDropdown";
import LanguagesDropdown from "./LanguagesDropdown";
import PYTHON_ICON from "../../assets/img/python.svg";
import PYTHON_COLOR_ICON from "../../assets/img/python-color_header.svg";
import THEME_ICON from "../../assets/img/paint-board.svg";
import SAVE_ICON from "../../assets/save.svg";
import CPP_ICON from '../../assets/img/cpp.svg';
import { SAMPLE_CODES } from "../../constants/sampleCode";
import { monacoThemes } from "../../lib/defineTheme";
import { useDispatch, useSelector } from "react-redux";
import AuthButtons from "../learn-page/LearnHeader/AuthButtons.js";
import {decode} from 'html-entities';
import { updateCode } from "../../reducers/codes.js";

const CodeLanding = ({ defaultLang }) => {
	const codeData = useSelector(state => state.scratchGui.codes.codeData);
  console.log(codeData);
  
  const [code, setCode] = useState(defaultLang === 'cpp' ? SAMPLE_CODES.CPP : SAMPLE_CODES.PYHTON);
  const [codeName, setCodeName] = useState("");
  const [customInput, setCustomInput] = useState("");
  const [outputDetails, setOutputDetails] = useState(null);
  const [processing, setProcessing] = useState(null);
  const [theme, setTheme] = useState(monacoThemes[1]);
  const [language, setLanguage] = useState(defaultLang === 'cpp' ? languageOptions[1] : languageOptions[0]);

	const isAuthenticated = useSelector(state => state.scratchGui.auth.isAuthenticated)

  const enterPress = useKeyPress("Enter");
  const ctrlPress = useKeyPress("Control");

  const dispatch = useDispatch();

  const onSelectChange = (sl) => {
    setLanguage(sl);
    if(sl.value === 'cpp') {
      setCode(SAMPLE_CODES.CPP)
    } else {
      setCode(SAMPLE_CODES.PYHTON)
    }
  };

  useEffect(() => {
    if (enterPress && ctrlPress) {
      console.log("enterPress", enterPress);
      console.log("ctrlPress", ctrlPress);
      handleCompile();
    }
  }, [ctrlPress, enterPress]);
  const onChange = (action, data) => {
    switch (action) {
      case "code": {
        setCode(data)
        break;
      }
      default: {
        console.warn("case not handled!", action, data);
      }
    }
  };
  const handleCompile = () => {
    setProcessing(true);
    const formData = {
      language_id: language.id,
      // encode source code in base64
      source_code: btoa(code),
      stdin: btoa(customInput),
    };
    const options = {
      method: "POST",
      url: "https://judge0-ce.p.rapidapi.com/submissions",
      params: { base64_encoded: "true", fields: "*" },
      headers: {
        "content-type": "application/json",
        "Content-Type": "application/json",
        "X-RapidAPI-Host": "judge0-ce.p.rapidapi.com",
        "X-RapidAPI-Key": "11549a236fmsh3c0195dce97ddb7p1e24b2jsn736b7d7d5adb",
      },
      data: formData,
    };

    axios
      .request(options)
      .then(function (response) {
        console.log("res.data", response.data);
        const token = response.data.token;
        checkStatus(token);
      })
      .catch((err) => {
        let error = err.response ? err.response.data : err;
        // get error status
        let status = err.response.status;
        console.log("status", status);
        if (status === 429) {
          console.log("too many requests", status);

          showErrorToast(
            `Quota of 100 requests exceeded for the Day! Please read the blog on freeCodeCamp to learn how to setup your own RAPID API Judge0!`,
            10000
          );
        }
        setProcessing(false);
        console.log("catch block...", error);
      });
  };

  const checkStatus = async (token) => {
    const options = {
      method: "GET",
      url: "https://judge0-ce.p.rapidapi.com/submissions" + "/" + token,
      params: { base64_encoded: "true", fields: "*" },
      headers: {
        "X-RapidAPI-Host": "judge0-ce.p.rapidapi.com",
        "X-RapidAPI-Key": "11549a236fmsh3c0195dce97ddb7p1e24b2jsn736b7d7d5adb",
      },
    };
    try {
      let response = await axios.request(options);
      let statusId = response.data.status?.id;

      // Processed - we have a result
      if (statusId === 1 || statusId === 2) {
        // still processing
        setTimeout(() => {
          checkStatus(token);
        }, 2000);
        return;
      } else {
        setProcessing(false);
        setOutputDetails(response.data);
        if(response.data?.status_id > 3){
          showErrorToast(response.data?.status?.description)
        } else {
          showSuccessToast(`Compiled Successfully!`);
        }
        return;
      }
    } catch (err) {
      console.log("err", err);
      setProcessing(false);
      showErrorToast();
    }
  };

  function handleThemeChange(th) {
    setTheme(th)
  }

  //Load Code from project modal
  useEffect(() => {
    setCodeName(codeData.codeName ? codeData.codeName : "")
    setCode(codeData.code ? decode(codeData.code, {level: 'html5'}) : (defaultLang === 'cpp' ? SAMPLE_CODES.CPP : SAMPLE_CODES.PYHTON))
    setLanguage(codeData.codeType ? (codeData.codeType === 'cpp' ? languageOptions[1] : languageOptions[0]) : (defaultLang === 'cpp' ? languageOptions[1] : languageOptions[0]))
  }, [codeData]);

  const showSuccessToast = (msg) => {
    toast.success(msg || `Compiled Successfully!`, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const showErrorToast = (msg, timer) => {
    toast.error(msg || `Something went wrong! Please try again.`, {
      position: "top-right",
      autoClose: timer ? timer : 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

const handleSave = () => {
  if(!isAuthenticated) {
    showErrorToast("Login to save code", 3000)
  } else if(!codeName){
    showErrorToast("Please Enter Project Name", 3000)
  } else if(!code){
    showErrorToast("Please Enter Code", 3000)
  } else {
    if(isAuthenticated || codeName || code) {
      if(codeData){
        dispatch(updateCode({ code, codeName, codeType: language.value, codeId: codeData._id }));
        showSuccessToast("Code Updated!", 3000)
      } else {
        dispatch(createCode({ code, codeName, codeType: language.value }));
        showSuccessToast("Code Added!", 3000)
      }
    }
  }
}  

  return (
    <div className={styles.code_ide}>
      <Overlay />
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					background: '#3b5998',
          width: '100%',
					padding: '10px 34px',
          fontFamily: "'Poppins', sans-serif"
				}}
			>
        <div className="flex items-center gap-2">
          <img 
            src={language?.value === 'cpp' ? CPP_ICON : PYTHON_COLOR_ICON} 
            alt="" 
            style={{
              width: '64px', 
              height: '64px'
            }} 
          />
          <h2 className={`${styles.page_title} h-auto`}>{language?.value === 'cpp' ? '' : 'PYTHON'}</h2>
        </div>
        <AuthButtons />
			</div>
      <div className="flex flex-row items-center flex-wrap">
        <div className="px-4 py-2 flex items-center gap-3">
          <img 
            src={language?.value === 'cpp' ? CPP_ICON : PYTHON_ICON} 
            style={{ 
              width: '28px', 
              height: '28px', 
              objectFit: 'contain' 
            }} 
            alt="language" 
          />
          <LanguagesDropdown onSelectChange={onSelectChange} defaultValue={language} />
        </div>
        <div className="px-4 py-2 flex items-center gap-3">
          <img src={THEME_ICON} style={{ width: '24px', height: '24px', objectFit: 'contain' }} alt="theme" />
          <ThemeDropdown handleThemeChange={handleThemeChange} theme={theme} />
        </div>
        <input type="text" placeholder="Enter Project Name" value={codeName} onChange={(e) => setCodeName(e.target.value)} className="border-2 border-[#3b5998] rounded-md shadow-[2px_2px_0px_0px_rgba(59,89,152)] px-4 py-2 mx-4 my-2 outline-none" />
        <button 
          className="flex items-center gap-3 h-fit border-2 border-[#3b5998] rounded-md shadow-[2px_2px_0px_0px_rgba(59,89,152)] px-4 py-2 mx-4 my-2 hover:shadow transition duration-200 bg-white font-semibold text-[#3b5998] !text-sm"
          onClick={handleSave}
        >
          <img src={SAVE_ICON} alt="save" className="size-5" />
          <p>Save</p>
        </button>
      </div>
      <div className="flex flex-col md:flex-row space-x-4 items-start mx-4 py-4">
        <div className="flex flex-col w-full md:w-[68%] h-full justify-start items-end">
          <CodeEditorWindow
            code={code}
            onChange={onChange}
            language={language?.value}
            theme={theme.value}
          />
        </div>
        <div className="right-container flex flex-shrink-0 w-[80%] md:w-[32%] flex-col pr-5">
          <OutputWindow outputDetails={outputDetails} />
          <div className="flex flex-col items-end">
            <CustomInput
              customInput={customInput}
              setCustomInput={setCustomInput}
            />
            <button
              onClick={handleCompile}
              disabled={!code}
              className={classnames(
                "mt-4 border-2 border-[#3b5998] z-10 rounded-md shadow-[5px_5px_0px_0px_rgba(59,89,152)] px-4 py-2 hover:shadow transition duration-200 bg-white flex-shrink-0 font-semibold text-[#3b5998]",
                !code ? "opacity-50" : ""
              )}
            >
              {processing ? "Processing..." : "Compile and Execute"}
            </button>
          </div>
          {outputDetails && <OutputDetails outputDetails={outputDetails} />}
        </div>
      </div>
    </div>
  );
};
export default CodeLanding;
