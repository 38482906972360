import { loadUser } from "../reducers/auth";

export const classnames = (...args) => {
  return args.join(" ");
};

export const responseGoogle = async (authResult) => {
  try {
      if (authResult["code"]) {
          loadUser()
      } else {
          console.log(authResult);
          throw new Error(authResult);
      }
  } catch (e) {
      console.log(e);
  }
};