import React from 'react';
import robot from '../../../assets/robot-learning.png';
import logo from '../../../assets/logo.png';
import styles from './LearnHeader.css';
import AuthButtons from './AuthButtons.js';

const LearnHeader = () => {
	return (
		<header className={styles.learnHeader}>
			<nav className='flex justify-between items-center px-5 py-4 bg-[#3b5998]'>
				<img src={logo} alt="" style={{
					width: '250px'
				}} />
				<AuthButtons />
			</nav>
			<div className={`px-6 lg:px-12  ${styles.topHero}  `}>
				<div className={`${styles.heroContent} mb-0 justify-start relative w-fit mx-auto`}>
					<img className='w-[210px] absolute right-[75%] top-[-55px]' src={robot} alt='' />
					<h3 className={`${styles.bannerTitle} px-12 py-6 rounded-xl mt-11`}>Start Learning</h3>
				</div>
			</div>
		</header>
	);
};

export default LearnHeader;
