import React from 'react';
import AI from '../../../assets/bot-learn-1.svg';
import Robotic from '../../../assets/bot-learn-2.svg';
import Block from '../../../assets/bot-learn-3.svg';
import styles from './MakeProject.css';

const MakeProject = () => {
	return (
		<section className={`${styles.makeProject} mt-[100px] mb-[200px]`}>
			<h3 className='pb-36 text-center'>Make Projects in TinkerBrix </h3>
			<div
				className={`px-6 lg:px-12 lg:flex justify-around ${styles.subjectContainer} items-center gap-[72px]`}
			>
				<div className={`${styles.subject} mt-20`}>
					<img src={AI} alt='' />
					<h3>AI</h3>
				</div>
				<div className={styles.subject}>
					<img src={Robotic} alt='' />
					<h3>Robotics</h3>
				</div>
				<div className={styles.subject}>
					<img src={Block} alt='' />
					<h3>Block Coding</h3>
				</div>
			</div>
		</section>
	);
};

export default MakeProject;
