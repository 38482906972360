import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import book1 from '../../assets/book-1.svg';
import book from '../../assets/book.svg';
import educators from '../../assets/educator.svg';
import logo from '../../assets/logo.svg';
import logout from '../../assets/logout.svg';
import messagesIcon from '../../assets/messages-icon.svg';
import people from '../../assets/people-1.svg';
import robot from '../../assets/robot-3.svg';
import rocket from '../../assets/roket.svg';
import teacher from '../../assets/teacher-1.svg';
import styles from './Navbar.css';
import { useSelector } from 'react-redux';
import Avatar from '../Avatar/Avatar.jsx';

const Navbar = ({ children }) => {
	// eslint-disable-next-line no-unused-vars
	const isAuthenticated = useSelector(state => state.scratchGui.auth.isAuthenticated);
	const avatar = useSelector(state => state.scratchGui.auth.avatar);

	return (
		<>
			<nav className='block lg:hidden'>
				<div className='drawer'>
					<input id='my-drawer' type='checkbox' className='drawer-toggle' />
					<div className='drawer-content mx-5 mt-5'>
						{/* <!--//! Page content here --> */}
						<div className='flex justify-center items-center'>
							<label htmlFor='my-drawer' className='drawer-button'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									className='h-8 w-8 text-white'
									fill='none'
									viewBox='0 0 24 24'
									stroke='currentColor'
								>
									<path
										strokeLinecap='round'
										strokeLinejoin='round'
										strokeWidth='2'
										d='M4 6h16M4 12h8m-8 6h16'
									/>
								</svg>
							</label>
							<Link to='/' className='block mx-auto'>
								<img className='w-[200px]' src={logo} alt='' />
							</Link>
						</div>
						<div className={styles.topHero}>{children}</div>
					</div>
					<div className='drawer-side'>
						<label htmlFor='my-drawer' className='drawer-overlay' />
						<ul className='menu p-4 overflow-y-auto w-80 bg-white text-base-content'>
							{/* <!--//! Sidebar content here --> */}
							<label
								htmlFor='my-drawer'
								className={`absolute right-6 top-4 ${styles.closeBtn} cursor-pointer`}
							>
								✕
							</label>

							{isAuthenticated ? (
								<div className='flex items-center justify-center text-center mt-16'>
									<div>
										<div className='w-[60px] rounded-full bg-[#6837F1]'>
											<img className='rounded-full' src={avatar} alt='' />
										</div>
									</div>
									<p className={styles.userGreet}>Hi user!</p>
								</div>
							) : (
								<div className='flex items-center justify-center text-center mt-16'>
									<p className={`bg-[#6837F1] ${styles.loginMobileBtn} mr-3`}>
										<label htmlFor='login-modal' className='text-white cursor-pointer'>
											Log in
										</label>
									</p>
									<p className={styles.joinUs}>Join us!</p>
								</div>
							)}

							<Link to='/scratch'>
								<div className='mt-10 px-10 flex items-center'>
									<img className='w-[24px] mr-2' src={book} alt='' />
									<p>Start Learning</p>
								</div>
							</Link>

							{isAuthenticated && (
								<a href='#link'>
									<div className='mt-3 px-10 flex items-center'>
										<img className='w-[24px] mr-2' src={logout} alt='' />
										<p>Logout</p>
									</div>
								</a>
							)}
						</ul>
					</div>
				</div>
			</nav>
			<nav className='navbar px-6 lg:px-12 hidden lg:block'>
				<div className={`flex ${styles.menuContainer} justify-between items-center`}>
					<div>
						<a href='#link' className=' text-xl'>
							<img src={logo} alt='' />
						</a>
					</div>
					<ul className='menu menu-horizontal p-0 items-center'>
						<li>
							<Link to='/scratch'>
								<img src={book1} alt='' />
								<span>Start Learning</span>
							</Link>
						</li>

						{!isAuthenticated ? (
							<>
								<li>
									<label
										htmlFor='login-modal'
										className={`bg-[#6837F1] rounded-3xl mx-2 px-10 ${styles.loginBtn} text-white`}
									>
										Log in
									</label>
								</li>
								<li>
									<label htmlFor='signup-modal' className='text-white'>
										Sign up
									</label>
								</li>{' '}
							</>
						) : (
							<Avatar />
						)}
					</ul>
				</div>
			</nav>
		</>
	);
};

export default Navbar;
