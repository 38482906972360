exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".CustomButton_start-learning_37tjB{background-color:#f75d5b;cursor:pointer;color:#fff;border-radius:44px;padding:15px 40px;font-size:18px;line-height:28px;margin-top:41px;-webkit-box-shadow:-2px 3px 0px 1px #c84c25;box-shadow:-2px 3px 0px 1px #c84c25}.CustomButton_start-learning_37tjB:active{-webkit-box-shadow:none;box-shadow:none}", ""]);

// exports
exports.locals = {
	"start-learning": "CustomButton_start-learning_37tjB",
	"startLearning": "CustomButton_start-learning_37tjB"
};