import React, { useState } from 'react';
import styles from './LearnHeader.css';
import classNames from 'classnames';
import Login from '../../landing-page/Login/Login.jsx';
import Signup from '../../landing-page/Signup/Signup.jsx';
import MyProjectsModal from '../../my-projects-modal/MyProjectModal.jsx';
import { useSelector } from 'react-redux';
import projectIcon from './icon--mystuff.png';
import {
	saveMenuOpen,
} from '../../../reducers/menus';
import Avatar from '../../../shared/Avatar/Avatar.jsx';

const AuthButtons = () => {
	const isAuthenticated = useSelector(state => state.scratchGui.auth.isAuthenticated);
	const [isMyProjectsModalOpen, setIsMyProjectsModalOpen] = useState(false);
  return (
    <>
        {!isAuthenticated && (
            <div className='flex items-center gap-2'>
                <div className='flex items-center justify-center text-center'>
                    <label
                        htmlFor='login-modal'
                        className={`py-2 px-[1.5vw] rounded-xl font-semibold text-sm ${styles.loginBtn} text-white cursor-pointer`}
                    >
                        Log in
                    </label>
                </div>
                <div className={`flex items-center justify-center text-center font-semibold text-sm`}>
                    <label htmlFor='signup-modal' className={`text-white cursor-pointer py-2 px-[1.5vw] rounded-xl ${styles.signupBtn}`}>
                        Sign Up
                    </label>
                </div>
            </div>
        )}
        {isAuthenticated && (
            <div
                className={classNames('px-2', styles.menuBarItem, styles.hoverable, {
                    [styles.active]: saveMenuOpen,
                })}
                onClick={() => setIsMyProjectsModalOpen(true)}
            >
                <img
                    onClick={() => this.getSaveToCloudHandler()}
                    className={styles.saveIcon}
                    src={projectIcon}
                />
                <p className='px-2'>My Projects</p>
            </div>
        )}
        {isAuthenticated && <Avatar />}
        {true && <Login />}
        {true && <Signup />}
        {true && (
            <MyProjectsModal
                isVisible={isMyProjectsModalOpen}
                hideModal={() => setIsMyProjectsModalOpen(false)}
            />
        )}
    </>
  )
}

export default AuthButtons