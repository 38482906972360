import React from 'react';
import ProfileHeader from '../components/dashboard/ProfileHeader/ProfileHeader';
import Projects from '../components/dashboard/Projects/Projects';
import Footer from '../shared/Footer/Footer';

const Dashboard = () => {
    return (
        <div>
            <ProfileHeader />
            <Projects />
            <Footer />
        </div>
    );
};

export default Dashboard;