import React from 'react';
import Header from '../components/landing-page/Header/Header';
import styles from '../components/landing-page/Header/Header.css';
import MakeProject from '../components/landing-page/MakeProject/MakeProject';
import Parents from '../components/landing-page/Parents/Parents';
import Services from '../components/landing-page/Services/Services';
import Testimonials from '../components/landing-page/Testimonials/Testimonials';
import WhyRobospecies from '../components/landing-page/WhyRobospecies/WhyRobospecies';
import Footer from '../shared/Footer/Footer';

function Landing() {
	return (
		<div className={styles.landingContainer}>
			<Header />
			<WhyRobospecies />
			<Parents />
			<Services />
			<Testimonials />
			<MakeProject />
			<Footer />
		</div>
	);
}

export default Landing;
