import React from 'react';
import CustomButton from '../../../shared/CustomButton/CustomButton';
import Footer from '../../../shared/Footer/Footer';
import styles from './Message.css';
import MessageHeader from './MessageHeader';

const Message = () => {
    return (
      <div>
        <MessageHeader />
        <div className='flex justify-end'>
          <div className={`${styles.filterContainer} py-5 px-6`}>
            <select className={`select ${styles.filter}`}>
              <option disabled selected>
                Filters
              </option>
              <option>Homer</option>
              <option>Marge</option>
              <option>Bart</option>
              <option>Lisa</option>
              <option>Maggie</option>
            </select>
          </div>
        </div>
        <div className={`${styles.messageContainer} px-12`}>
          <h3>No messages found!</h3>
          <div className='card w-full border mt-[16px]'>
            <div className='card-body'>
              <p>
                Hi there! Your inbox is currently empty. You will receive
                messages and notifications here.
              </p>
              <div className='card-actions justify-end'>
                <CustomButton>+ Create Now</CustomButton>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
};

export default Message;