import React from 'react';
import styles from './PageHeader.css';

const PageHeader = () => {
    return (
      <div className={`flex justify-around gap-5 ${styles.pageMenubar}`}>
        <li>
          <span>All</span>
        </li>
        <li>
          <span>Robotics</span>
        </li>
        <li>
          <span>AI</span>
        </li>
        <li className={styles.blockCoding}>
          <span>Block Coding</span>
        </li>
      </div>
    );
};

export default PageHeader;