exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;200;300;400;500;600;700&display=swap);", ""]);

// module
exports.push([module.id, ".CodeLanding_code_ide_1tg4E{\r\n    font-family: 'IBM Plex Sans', sans-serif;\r\n    font-weight: 300;\r\n    font-size: 14px;\r\n    position: relative;\r\n}\r\n\r\n.CodeLanding_page_title_3AaHd{\r\n    font-family: 'IBM Plex Sans', sans-serif;\r\n    font-weight: 300;\r\n    font-size: 1.75rem;\r\n    color: #FFFFFF;\r\n    font-weight: 600;\r\n}", ""]);

// exports
exports.locals = {
	"code_ide": "CodeLanding_code_ide_1tg4E",
	"codeIde": "CodeLanding_code_ide_1tg4E",
	"page_title": "CodeLanding_page_title_3AaHd",
	"pageTitle": "CodeLanding_page_title_3AaHd"
};