import React from 'react'
import logo from '../../assets/logo.png';
import { Link } from 'react-router-dom';

const Overlay = ({ className }) => {
  return (
    <div className={`fixed bottom-4 transition-all right-6 opacity-40 hover:opacity-100 bg-[#3b5998] p-2 rounded-xl z-10 ${className}`}>
        <Link to='/'>
            <img src={logo} alt='Logo' style={{ height: '50px' }} />
        </Link>
    </div>
  )
}

export default Overlay