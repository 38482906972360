import React from 'react';
import CustomButton from '../../../shared/CustomButton/CustomButton';
import styles from './WhyRobospecies.css';

const WhyRobospecies = () => {
	return (
		<section className={`px-6 md:px-12 pt-5 pb-20 md:pb-36 ${styles.whyRobospeciesSection}`}>
			<div>
				<h2 className={styles.sectionTitle}>Why TinkerBrix ?</h2>
				<div className='flex flex-col md:flex-row justify-around items-center text-center gap-10 mt-[40px]'>
					<div>
						<h4 className='font-semibold text-black mb-3'>FREE Simulation</h4>
						<p className={styles.robospecies_text}>
							A FREE Simulation Based Learning Tool to Learn Robotics, Coding & AI in One Stop
							Solution. It is Scratch based graphically rich programming & coding software.
						</p>
					</div>
					<div>
						<h4 className='font-semibold text-black mb-3'>All In One Solution</h4>
						<p className={styles.robospecies_text}>
							It is an all-in-one solution for any student or educator to kickstart into the world
							of Electronics Simulations, Programming in Blocks and Scripts, working with Arduino.
						</p>
					</div>
					<div>
						<h4 className='font-semibold text-black mb-3'>User Friendly UI</h4>
						<p className={styles.robospecies_text}>
							User-friendly interface with drag-and-drop functionality. Develop skills such as
							logical reasoning, problem-solving & critical thinking
						</p>
					</div>
				</div>
				<div className='text-center py-10 mt-[41px]'>
					<CustomButton>Start Learning!</CustomButton>
				</div>
			</div>
		</section>
	);
};

export default WhyRobospecies;
