import React, { useState } from "react";
import AuthButtons from "../learn-page/LearnHeader/AuthButtons";
import PHYSICS_ICON from "../../assets/img/physics.png";
import BACK_ICON from "../../assets/img/back_arrow_icon.svg";
import Overlay from "../../shared/Overlay/Overlay.jsx";
import PhysicsLandingMenu from "./physics-engine-code/physics-landing-menu.jsx";
import PhysicsExamples from "./physics-engine-code/physics-example.jsx";
import FPS from './physics-engine-code/images/fps.png';
import MOUSEPICK from './physics-engine-code/images/threejs_mousepick.png';
import VOXEL from './physics-engine-code/images/threejs_voxel_fps.png';
import BOUNCE from './physics-engine-code/images/bounce.png';
import BUNNY from './physics-engine-code/images/bunny.png';
import CALLBACKS from './physics-engine-code/images/callbacks.png';
import CLOTH from './physics-engine-code/images/threejs_cloth.png';
import WORKER from './physics-engine-code/images/worker.png';
import MOTIONSTATE from './physics-engine-code/images/motionstates.png';
import COLLISIONFILTER from './physics-engine-code/images/collisionFilter.png';
import COLLISION from './physics-engine-code/images/collisions.png';
import COMPOUND from './physics-engine-code/images/compound.png';
import CONSTRAINTS from './physics-engine-code/images/constraints.png';
import CONTAINER2 from './physics-engine-code/images/container2.png';
import CONVEXHULL from './physics-engine-code/images/convexhull.png';
import EVENTS from './physics-engine-code/images/events.png';
import FIXEDROTATION from './physics-engine-code/images/fixedRotation.png';
import FRICTION from './physics-engine-code/images/friction.png';
import HEIGHTFIELD from './physics-engine-code/images/heightfield.png';
import HINGE from './physics-engine-code/images/hinge.png';
import IMPULSES from './physics-engine-code/images/impulses.png';
import PILE from './physics-engine-code/images/pile.png';
import RIGIDVEHICLE from './physics-engine-code/images/rigidVehicle.png';
import RAYCASTVEHICLE from './physics-engine-code/images/raycastVehicle.png';
import SHAPES from './physics-engine-code/images/shapes.png';
import SINGLE from './physics-engine-code/images/single.png';
import SLEEP from './physics-engine-code/images/sleep.png';
import SPH from './physics-engine-code/images/sph.png';
import SPLITSOLVER from './physics-engine-code/images/splitSolver.png';
import SPRING from './physics-engine-code/images/spring.png';
import STACK from './physics-engine-code/images/stack.png';

const EXAMPLES = [
  {
      img: BOUNCE,
      title: "Bounce",
      url: "https://physics-engine-peach.vercel.app/demos/bounce.html"
  },
  {
      img: BUNNY,
      title: "Bunny",
      url: "https://physics-engine-peach.vercel.app/demos/bunny.html"
  },
  {
      img: CALLBACKS,
      title: "Callbacks",
      url: "https://physics-engine-peach.vercel.app/demos/callbacks.html"
  },
  {
      img: CLOTH,
      title: "Cloth",
      url: "https://physics-engine-peach.vercel.app/examples/threejs_cloth.html"
  },
  {
      img: WORKER,
      title: "Worker",
      url: "https://physics-engine-peach.vercel.app/examples/worker.html"
  },
  {
      img: MOTIONSTATE,
      title: "Body Types",
      url: "https://physics-engine-peach.vercel.app/demos/bodyTypes.html"
  },
  {
      img: COLLISIONFILTER,
      title: "Collision Filter",
      url: "https://physics-engine-peach.vercel.app/demos/collisionFilter.html"
  },
  {
      img: COLLISION,
      title: "Collisions",
      url: "https://physics-engine-peach.vercel.app/demos/collisions.html"
  },
  {
      img: COMPOUND,
      title: "Compound",
      url: "https://physics-engine-peach.vercel.app/demos/compound.html"
  },
  {
      img: CONSTRAINTS,
      title: "Container",
      url: "https://physics-engine-peach.vercel.app/demos/container.html"
  },
  {
      img: CONTAINER2,
      title: "Container",
      url: "https://physics-engine-peach.vercel.app/demos/container.html"
  },
  {
      img: CONVEXHULL,
      title: "Convex Hull",
      url: "https://physics-engine-peach.vercel.app/demos/convex.html"
  },
  {
      img: EVENTS,
      title: "Events",
      url: "https://physics-engine-peach.vercel.app/demos/events.html"
  },
  {
      img: FIXEDROTATION,
      title: "Fixed Rotation",
      url: "https://physics-engine-peach.vercel.app/demos/fixedRotation.html"
  },
  {
      img: FRICTION,
      title: "Friction",
      url: "https://physics-engine-peach.vercel.app/demos/friction.html"
  },
  {
      img: HEIGHTFIELD,
      title: "Height Field",
      url: "https://physics-engine-peach.vercel.app/demos/heightfield.html"
  },
  {
      img: HINGE,
      title: "Hinge",
      url: "https://physics-engine-peach.vercel.app/demos/hinge.html"
  },
  {
      img: IMPULSES,
      title: "Impluses",
      url: "https://physics-engine-peach.vercel.app/demos/impulses.html"
  },
  {
      img: PILE,
      title: "Pile",
      url: "https://physics-engine-peach.vercel.app/demos/pile.html"
  },
  {
      img: RIGIDVEHICLE,
      title: "Rigid Vehicle",
      url: "https://physics-engine-peach.vercel.app/demos/rigidVehicle.html"
  },
  {
      img: SHAPES,
      title: "Shapes",
      url: "https://physics-engine-peach.vercel.app/demos/shapes.html"
  },
  {
      img: SINGLE,
      title: "Single Body",
      url: "https://physics-engine-peach.vercel.app/demos/singleBodyOnPlane.html"
  },
  {
      img: SLEEP,
      title: "Sleep",
      url: "https://physics-engine-peach.vercel.app/demos/sleep.html"
  },
  {
      img: SPH,
      title: "SPH",
      url: "https://physics-engine-peach.vercel.app/demos/sph.html"
  },
  {
      img: SPLITSOLVER,
      title: "Split Solver",
      url: "https://physics-engine-peach.vercel.app/demos/splitSolver.html"
  },
  {
      img: SPRING,
      title: "SPRING",
      url: "https://physics-engine-peach.vercel.app/demos/spring.html"
  },
  {
      img: STACK,
      title: "Stacks",
      url: "https://physics-engine-peach.vercel.app/demos/stacks.html"
  },
]

const GAMES = [
  {
      img: FPS,
      title: "Shooter",
      url: "https://physics-engine-peach.vercel.app/examples/threejs_fps.html"
  },
  {
      img: MOUSEPICK,
      title: "Mousepick",
      url: "https://physics-engine-peach.vercel.app/examples/threejs_mousepick.html"
  },
  {
      img: VOXEL,
      title: "Voxel landscape",
      url: "https://physics-engine-peach.vercel.app/examples/threejs_voxel_fps.html"
  },
  {
      img: RAYCASTVEHICLE,
      title: "Ray Cast Vehicle",
      url: "https://physics-engine-peach.vercel.app/demos/raycastVehicle.html"
  },
]

const Physics = () => {
    const [menuOpened, setMenuOpened] = useState('/');

   return (
    <div className={`w-full bg-[#121212] min-h-[100dvh]`}>
      <Overlay />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          background: "#3b5998",
          width: "100%",
          padding: "10px 34px",
          fontFamily: "'Poppins', sans-serif",
        }}
      >
        <div className="flex items-center gap-3">
          <img
            src={PHYSICS_ICON}
            alt=""
            style={{
              width: "64px",
              height: "64px",
            }}
          />
          <h2 className={`text-white h-auto`}>PHYSICS ENGINE</h2>
        </div>
        <AuthButtons />
      </div>
      <div
        className="flex flex-col items-center my-12 mx-auto w-100% max-w-[1400px] px-[5%]"
        style={{ fontFamily: "Poppins" }}
      >
        {menuOpened !== '/' && <button className="flex items-center gap-3 text-white font-semibold mr-auto mb-4" onClick={() => setMenuOpened('/')}><img src={BACK_ICON} alt="BACK" className="size-8" /> Back</button>}
        <h1 className="text-4xl font-bold text-center text-[#FDB302]">
            {menuOpened === '/' && "Physics Interactive Simulations"}
            {menuOpened === 'examples' && "Physics Examples"}
            {menuOpened === 'games' && "Games"}
        </h1>
        <p className="mt-4 font-medium max-w-[550px] text-center text-white">
            {menuOpened === '/' && "Create interactive physics simulations to demonstrate basic physics concepts in the classroom."}
            {menuOpened === 'examples' && "Prebuild interactive physics simulations to demonstrate what are the possible outcome."}
            {menuOpened === 'games' && "Interactive games build with interactive physics simulations."}
        </p>
        {menuOpened === '/' && <PhysicsLandingMenu setMenuOpened={setMenuOpened} />}
        {menuOpened === 'examples' && <PhysicsExamples EXAMPLES={EXAMPLES} />}
        {menuOpened === 'games' && <PhysicsExamples EXAMPLES={GAMES} />}
      </div>
    </div>
  );
};

export default Physics;
