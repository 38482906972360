exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".MakeProject_make-project_1HYTk h3{font-family:\"Kufam\";font-style:normal;font-weight:700;font-size:30px;line-height:39px;color:#0c2032}.MakeProject_make-project_1HYTk .MakeProject_subject-container_2ixcH .MakeProject_subject_2CC96{width:324px;height:263.05px;background:#e7dfff;border-radius:40px;-webkit-box-shadow:8px 4px 0px 1px #9e88df;box-shadow:8px 4px 0px 1px #9e88df;margin:auto;text-align:center;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-webkit-align-items:center;-ms-flex-align:center;align-items:center}.MakeProject_make-project_1HYTk .MakeProject_subject-container_2ixcH .MakeProject_subject_2CC96 img{width:200px;text-align:center;margin-top:-65px}.MakeProject_make-project_1HYTk .MakeProject_subject-container_2ixcH .MakeProject_subject_2CC96 h3{margin-top:40px;font-family:\"Karla\";font-style:normal;font-weight:700;font-size:27px;line-height:32px;text-align:center;color:#3b3450}", ""]);

// exports
exports.locals = {
	"make-project": "MakeProject_make-project_1HYTk",
	"makeProject": "MakeProject_make-project_1HYTk",
	"subject-container": "MakeProject_subject-container_2ixcH",
	"subjectContainer": "MakeProject_subject-container_2ixcH",
	"subject": "MakeProject_subject_2CC96"
};