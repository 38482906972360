import { defineMessages } from 'react-intl';
import sharedMessages from '../shared-messages';

let messages = defineMessages({
	chirp: {
		defaultMessage: 'Chirp',
		description: 'Name for the chirp sound',
		id: 'gui.defaultProject.chirp',
	},
	variable: {
		defaultMessage: 'my variable',
		description: 'Name for the default variable',
		id: 'gui.defaultProject.variable',
	},
});

messages = { ...messages, ...sharedMessages };

// use the default message if a translation function is not passed
const defaultTranslator = msgObj => msgObj.defaultMessage;

/**
 * Generate a localized version of the default project
 * @param {function} translateFunction a function to use for translating the default names
 * @return {object} the project data json for the default project
 */
const projectData = translateFunction => {
	const translator = translateFunction || defaultTranslator;
	return {
		targets: [
			{
				isStage: true,
				name: 'Stage',
				variables: {},
				lists: {},
				broadcasts: {},
				blocks: {},
				currentCostume: 0,
				costumes: [
					{
						assetId: 'cd21514d0531fdffb22204e0ec5ed84a',
						name: translator(messages.backdrop, { index: 1 }),
						md5ext: 'cd21514d0531fdffb22204e0ec5ed84a.svg',
						dataFormat: 'svg',
						rotationCenterX: 240,
						rotationCenterY: 180,
					},
				],
				sounds: [
					{
						assetId: '83a9787d4cb6f3b7632b4ddfebf74367',
						name: translator(messages.pop),
						dataFormat: 'wav',
						format: '',
						rate: 11025,
						sampleCount: 258,
						md5ext: '83a9787d4cb6f3b7632b4ddfebf74367.wav',
					},
				],
				volume: 100,
			},
			{
				isStage: false,
				name: 'Duggu',
				variables: {},
				lists: {},
				broadcasts: {},
				blocks: {},
				currentCostume: 0,
				tags: ['robot'],
				isStage: false,
				variables: {},
				costumes: [
					{
						assetId: '35C3C2489DF83730139B180042270877',
						name: 'Duggu-a',
						bitmapResolution: 1,
						md5ext: '35C3C2489DF83730139B180042270877.svg',
						dataFormat: 'svg',
						rotationCenterX: 250,
						rotationCenterY: 250,
					},
					{
						assetId: 'CBB67F48B0F73380C63C3605CA66DC7E',
						name: 'Duggu-b',
						bitmapResolution: 1,
						md5ext: 'CBB67F48B0F73380C63C3605CA66DC7E.svg',
						dataFormat: 'svg',
						rotationCenterX: 250,
						rotationCenterY: 250,
					},
					{
						assetId: 'F0543F6BD15825DBDBEA827B6DEBC75D',
						name: 'Duggu-c',
						bitmapResolution: 1,
						md5ext: 'F0543F6BD15825DBDBEA827B6DEBC75D.svg',
						dataFormat: 'svg',
						rotationCenterX: 250,
						rotationCenterY: 250,
					},
					{
						assetId: 'A0A144674062E50BFB6816E29FCC3EC6',
						name: 'Duggu-d',
						bitmapResolution: 1,
						md5ext: 'A0A144674062E50BFB6816E29FCC3EC6.svg',
						dataFormat: 'svg',
						rotationCenterX: 250,
						rotationCenterY: 250,
					},
					{
						assetId: '08B4D7BD9DE2FD42C3208ACE16766E1A',
						name: 'Duggu-e',
						bitmapResolution: 1,
						md5ext: '08B4D7BD9DE2FD42C3208ACE16766E1A.svg',
						dataFormat: 'svg',
						rotationCenterX: 250,
						rotationCenterY: 250,
					},
				],
				sounds: [
					{
						assetId: '83a9787d4cb6f3b7632b4ddfebf74367',
						name: 'pop',
						dataFormat: 'wav',
						format: '',
						rate: 44100,
						sampleCount: 1032,
						md5ext: '83a9787d4cb6f3b7632b4ddfebf74367.wav',
					},
				],
				volume: 100,
				visible: true,
				x: 0,
				y: 0,
				size: 50,
				direction: 90,
				draggable: false,
				rotationStyle: 'all around',
			},
		],
		meta: {
			semver: '3.0.0',
			vm: '0.1.0',
			agent:
				'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_13_3) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/65.0.3325.181 Safari/537.36', // eslint-disable-line max-len
		},
	};
};

export default projectData;
