import React from 'react';
import logo from '../assets/logo.png';
import { Link } from 'react-router-dom';

const IframePage = ({ iframe, addon, className = "", overflow = true, Header }) => {
	return (
		<div style={{ position: 'relative', height: '100vh', overflow: overflow ? 'auto' : 'hidden' }}>
			<div
				className={`${className}`}
				style={{
					position: 'absolute',
					top: 0,
					left: 0,
					right: 0,
					width: "100%",
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					background: '#3b5998',
					padding: '10px',
				}}
			>
				{Header ? Header : <Link to='/'>
					<img src={logo} alt='Logo' style={{ height: '50px' }} />
				</Link>}
			</div>
			{addon}
			{iframe}
		</div>
	);
};

export default IframePage;
