import React, { useEffect, useState } from 'react';
import deleteIcon from '../../../assets/delete-icon.svg';
import img from '../../../assets/project-1.png';
import PageHeader from '../../../shared/PageHeader/PageHeader';
import styles from './Projects.css';

const Projects = () => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    fetch(
      'https://raw.githubusercontent.com/azalamin/sass-practice/main/tutorials.json'
    )
      .then(res => res.json())
      .then(data => setProjects(data));
  }, []);

  return (
    <section className={`px-6 lg:px-12 ${styles.projectContainer}`}>
      <div className={`${styles.searchContainer} mt-[51px]`}>
        <div className={`${styles.searchContainer} w-full`}>
          <input
            type='text'
            placeholder='Search for quests or fun activities '
            className={styles.searchBox}
          />
          <button className={styles.searchButton}>Search</button>
        </div>
      </div>
      <div className='w-[50%] mt-[72px]'>
        <PageHeader />
      </div>
      {/* Menubar Dashboard */}
      <div className={`md:flex justify-between mt-[50px]`}>
        <div className={`${styles.projectMenu}`}>
          <li className={styles.activeItem}>
            <a href=''>All Projects</a>
          </li>
          <li>
            <a href=''>Published</a>
          </li>
          <li>
            <a href=''>Unpublished</a>
          </li>
          <li>
            <a href=''>Trash</a>
          </li>
        </div>
        {/* Project Cards */}
        <div className='w-[65%]'>
          <h3 className={styles.projectTitle}>Your Projects</h3>
          <div className='grid grid-cols-3 gap-[33px] mt-[25px]'>
            {projects.map((project, index) => (
              <div className={styles.projectCard} key={index}>
                <div
                  className={`avatar placeholder cursor-pointer ${styles.deleteIcon}`}
                >
                  <div className='bg-white text-neutral-content rounded-full w-[32px]'>
                    <img className={styles.deleteImg} src={deleteIcon} alt='' />
                  </div>
                </div>
                <div className={styles.projectImg}>
                  <img src={img} alt='' />
                </div>
                <div className={styles.projectCardText}>
                  <h3>{project.title}</h3>
                  <p>{project.description}</p>
                </div>
              </div>
            ))}
          </div>
          <div className='py-20 text-center'>
            <button className={styles.nextPage}>{`Page ${1} of ${1}`}</button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Projects;
