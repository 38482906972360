exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* make sure to keep these in sync with other constants,\r\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */\r\n\r\n/* layout contants from `layout-constants.js` */\r\n\r\n/* #E5F0FF */\r\n\r\n/* #E9F1FC */\r\n\r\n/* #D9E3F2 */\r\n\r\n/* 90% transparent version of motion-primary */\r\n\r\n/* #FFFFFF */\r\n\r\n/* 25% transparent version of ui-white */\r\n\r\n/* 25% transparent version of ui-white */\r\n\r\n/* 25% transparent version of ui-white */\r\n\r\n/* 15% transparent version of black */\r\n\r\n/* #575E75 */\r\n\r\n/* #4C97FF */\r\n\r\n/* #3373CC */\r\n\r\n/* 35% transparent version of motion-primary */\r\n\r\n/* 15% transparent version of motion-primary */\r\n\r\n/* #FF661A */\r\n\r\n/* #E64D00 */\r\n\r\n/* #CF63CF */\r\n\r\n/* #BD42BD */\r\n\r\n/* #FFAB19 */\r\n\r\n/* #FF8C1A */\r\n\r\n/* #0FBD8C */\r\n\r\n/* #0FBD8C */\r\n\r\n/* #FF8C1A */\r\n\r\n/* #FFB366 */\r\n\r\n/* #FF8C1A */\r\n\r\n/* #0FBD8C */\r\n\r\n/* #0B8E69 */\r\n\r\n/* 35% transparent version of extensions-primary */\r\n\r\n/* opaque version of extensions-transparent, on white bg */\r\n\r\n/* lighter than motion-primary */\r\n\r\n.code-editor_code-editor_2Jblo * {\r\n    -webkit-box-sizing: border-box;\r\n            box-sizing: border-box;\r\n}\r\n", ""]);

// exports
exports.locals = {
	"code-editor": "code-editor_code-editor_2Jblo",
	"codeEditor": "code-editor_code-editor_2Jblo"
};